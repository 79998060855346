import { gql } from 'apollo-boost';

const CREATE_CREATOR_MUTATION = gql`
  mutation CreateCreatorMutation(
    $publisherId: Int!,
    $displayName: String!, 
    $paymentName: String!, 
    $photoUrl: String!, 
    $biography: String!, 
    $residence: String!,
    $email: String!,
    $phone: String!
  ) {
    createCreator(
      publisherId: $publisherId,
      displayName: $displayName, 
      paymentName: $paymentName, 
      photoUrl: $photoUrl, 
      biography: $biography, 
      residence: $residence,
      email: $email,
      phone: $phone
    ) {
      id
      publisherId
      displayName
      paymentName
      photoUrl
      biography
      residence
    }
  }
`;

export default CREATE_CREATOR_MUTATION;