import { gql } from 'apollo-boost';

const CREATE_PHONE_MUTATION = gql`
  mutation CreatePhoneMutation(
    $itemId: Int!,
    $contactType: String!,
    $data: String!
  ) {
    createPhone(
      itemId: $itemId,
      contactType: $contactType,
      data: $data
    ) {
      message
    }
  }
`;

export default CREATE_PHONE_MUTATION;