import { gql } from 'apollo-boost';

const UPDATE_ORDER_PAYMENT_MUTATION = gql`
  mutation UpdateOrderPaymentMutation(
    $id: Int!,
    $changed: String!
  ) {
    updateOrderPayment(
      id: $id,
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_ORDER_PAYMENT_MUTATION;