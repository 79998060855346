import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { updateStagedSet } from "../../util.js";

import SingleItemWithPrimary from "./SingleItemWithPrimary.js";
import AddressBox from "./AddressBox.js";

import CREATE_ADDRESS_MUTATION from "../../graphql/mutations/CreateAddress.js";
import UPDATE_ADDRESS_MUTATION from "../../graphql/mutations/UpdateAddress.js";
import CREATE_EMAIL_MUTATION from "../../graphql/mutations/CreateEmail.js";
import UPDATE_EMAIL_MUTATION from "../../graphql/mutations/UpdateEmail.js";
import DELETE_EMAIL_MUTATION from "../../graphql/mutations/DeleteEmail.js";
import CREATE_PHONE_MUTATION from "../../graphql/mutations/CreatePhone.js";
import UPDATE_PHONE_MUTATION from "../../graphql/mutations/UpdatePhone.js";
import DELETE_PHONE_MUTATION from "../../graphql/mutations/DeletePhone.js";
import DELETE_ADDRESS_MUTATION from "../../graphql/mutations/DeleteAddress.js";


import {
  Pane,
  Paragraph,

} from "evergreen-ui";

function ContactInfo(props) {
  // props.staged, props.setStaged, props.fieldDict, props.saved, props.setSaved, eventually props.contacttype
  // props.phoneSet, props.emailSet, props.addressSet
  const [changed, setChanged] = useState({ "Email": [], "Phone": [], "Address": [] })
  const [deleted, setDeleted] = useState({ "Email": [], "Phone": [], "Address": [] })

  const [updateAddress, { upaddressdata }] = useMutation(UPDATE_ADDRESS_MUTATION);
  const [updateEmail, { upemaildata }] = useMutation(UPDATE_EMAIL_MUTATION);
  const [updatePhone, { upphonedata }] = useMutation(UPDATE_PHONE_MUTATION);
  const [createAddress, { addressdata }] = useMutation(CREATE_ADDRESS_MUTATION);
  const [createEmail, { emaildata }] = useMutation(CREATE_EMAIL_MUTATION);
  const [createPhone, { phonedata }] = useMutation(CREATE_PHONE_MUTATION);
  const [deleteEmail, { emaildeldata }] = useMutation(DELETE_EMAIL_MUTATION);
  const [deletePhone, { phonedeldata }] = useMutation(DELETE_PHONE_MUTATION);
  const [deleteAddress, { addressdeldata }] = useMutation(DELETE_ADDRESS_MUTATION);


  const fieldMutationsMap = {
    'Email': {
      'create': createEmail,
      'update': updateEmail,
      'delete': deleteEmail
    },
    'Phone': {
      'create': createPhone,
      'update': updatePhone,
      'delete': deletePhone,
    },
    'Address': {
      'create': createAddress,
      'update': updateAddress,
      'delete': deleteAddress
    }

  }

  const handleUpdateContactInfo = async () => {

    let contactFields = ['Email', 'Phone', 'Address'];
    let contactsUpdated = await Promise.all(contactFields.map(async (field) => {
      let createArray = changed[field].filter(item => !item.id)
      if (createArray.length) {
        let createMutation = fieldMutationsMap[field].create;
        let createdItems = await Promise.all(createArray.map(async (item) => {
          let obj = Object.assign({}, item);
          delete obj.tmpid
          let createResult = await createMutation({
            variables: {
              contactType: props.contactType,
              itemId: parseInt(props.staged.id),
              data: JSON.stringify(obj)
            }
          })
          return createResult
        }))
        let createdNotif = createdItems.map(item => item.data['create' + field]?.message).join(', ').toString()
        console.log(createdNotif)
      }

      let updateArray = changed[field].filter(item => !item.tmpid);
      if (updateArray.length) {
        let updateMutation = fieldMutationsMap[field].update;
        let updatedItems = await Promise.all(updateArray.map(async (item) => {
          let obj = Object.assign({}, item);
          let itemId = obj.id;
          delete obj.id
          let updateResult = await updateMutation({
            variables: {
              contactType: props.contactType,
              itemId: parseInt(itemId),
              changed: JSON.stringify(obj)
            }
          })
          return updateResult;
        }))
        let updatedNotif = updatedItems.map(item => item.data['update' + field]?.message).join(', ').toString()
        console.log(updatedNotif)

      }

      let deleteArray = deleted[field]
      if (deleteArray.length) {
        let deleteMutation = fieldMutationsMap[field].delete;
        const deletedItems = await Promise.all(deleteArray.map(async (item) => {
          let deleteResult = await deleteMutation(
            {
              variables: {
                contactType: props.contactType,
                id: parseInt(item.id)
              }
            }
          )
          return deleteResult
        }))
        const deletedNotif = deletedItems.map(item => item.data['delete' + field]?.message).join(', ').toString()
        console.log(deletedNotif)

      }
      return `All ${field}s updated`
    }))

    const contactNotif = contactsUpdated.join(', ').toString()
    console.log(contactNotif)
    props.refetch()
    setChanged({ "Email": [], "Phone": [], "Address": [] })
    setDeleted({ "Email": [], "Phone": [], "Address": [] });

  }

  const handleUpdateStagedSet = (obj, fieldType) => {
    if (props.saved) { props.setSaved(false) }
    let fieldInfo = props.fieldDict[fieldType]
    let changedarr = [].concat(changed[fieldType]);
    let stagedarr = [].concat(props.staged[fieldInfo.setName]);

    updateStagedSet(obj, stagedarr, props.setStaged, changedarr, setChanged, fieldInfo.setName, fieldType)

  }

  const handleDelete = (obj, fieldType) => {
    if (props.saved) { props.setSaved(false) }

    let fieldInfo = props.fieldDict[fieldType]
    let stagedarr = props.staged[fieldInfo.setName];
    let changedarr = [].concat(changed[fieldType]);

    if (obj.id) {
      let deletedItems = deleted[fieldType];
      deletedItems.push(obj)
      setDeleted(oldState => ({ ...oldState, [fieldType]: deletedItems }))

      changedarr = changedarr.filter(item => item.id !== obj.id)
      setChanged(oldState => ({ ...oldState, [fieldType]: changedarr }))


      stagedarr = stagedarr.filter(item => item.id !== obj.id)
      props.setStaged(oldState => ({ ...oldState, [fieldInfo.setName]: stagedarr }));

    } else {
      changedarr = changedarr.filter(item => item.tmpid !== obj.tmpid)
      setChanged(oldState => ({ ...oldState, [fieldType]: changedarr }))

      stagedarr = stagedarr.filter(item => item.tmpid !== obj.tmpid)
      props.setStaged(oldState => ({ ...oldState, [fieldInfo.setName]: stagedarr }));
    }
  }

  useEffect(() => {
    if (props.saved) {
      handleUpdateContactInfo()
    }
  }, [props.saved]);


  return (
    <Pane display="flex">
      <Pane marginRight={props.editing ? 32 : 64} >
      <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>CONTACT INFO</Paragraph>

      <SingleItemWithPrimary
        itemSet={props.emailSet}
        editing={props.editing}
        cancelled={props.cancelled}
        fieldtype="Email"
        title="EMAIL ADDRESSES"
        handleUpdateStagedSet={handleUpdateStagedSet}
        handleDeleteItem={handleDelete}
      />
      <SingleItemWithPrimary
        itemSet={props.phoneSet}
        editing={props.editing}
        cancelled={props.cancelled}
        fieldtype="Phone"
        title="PHONE NUMBERS"
        handleUpdateStagedSet={handleUpdateStagedSet}
        handleDeleteItem={handleDelete}
      />

    </Pane>
      <Pane marginRight={props.editing ? 0 : 32} >
        <AddressBox
          cancelled={props.cancelled}
          itemaddressSet={props.addressSet}
          itemType={props.contactType}
          editing={props.editing}
          handleUpdateStagedSet={handleUpdateStagedSet}
          handleDeleteItem={handleDelete}
        />

      </Pane>
      </Pane>
  )
}

export default ContactInfo

