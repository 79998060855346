import React, { useEffect, useState } from "react";
import { Route, Switch, Link, Redirect, } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import { useSelector, useDispatch } from 'react-redux';
import { setcurrent } from './redux/currentpublisher/currentpublisherSlice.js';
import { setpublisherGlobalData } from './redux/currentpublisher/publisherGlobalDataSlice.js';
import { setcontribcodes } from './redux/onixcontributorcodes/contribcodesSlice.js';
import { setonixGlobalData } from './redux/onix/onixGlobalDataSlice.js';
import { setall } from './redux/publishers/publishersSlice.js';
import { setuser } from './redux/user/userSlice.js';
import Dash from "./views/Dashboard/Dash.js";
import UserSettings from "./views/Settings/UserSettings.js";
import PublisherSettings from "./views/Settings/PublisherSettings.js";
import Creators from "./views/Creators/Creators.js";
import Creator from "./views/Creators/Creator.js";
import Products from "./views/Products/Products.js";
import Product from "./views/Products/Product.js";
import Customers from "./views/Customers/Customers.js";
import Customer from "./views/Customers/Customer.js";
import Orders from "./views/Orders/Orders.js";
import Order from "./views/Orders/Order.js";
import Expenses from "./views/Expenses/Expenses.js";
import Expense from "./views/Expenses/Expense.js";
import Royalties from "./views/Royalties/Royalties.js";
import Royalty from "./views/Royalties/Royalty.js";
import RoyaltyPayments from "./views/RoyaltyPayments/RoyaltyPayments.js";
import NotFound from "./views/NotFound.js";
import UserMenu from "./components/layout/UserMenu.js";
import SideBar from "./components/layout/SideBar.js";
import MobileNav from "./components/layout/MobileNav.js";
import logo from './assets/images/logo-orange.png';
import QUERY_GLOBAL_DATA from "./graphql/queries/QueryGlobalData.js";
import './assets/styles/index.css';
import inboxmsgs from './inboxmsgs.json'
import { getCookie, makeDateString } from "./util.js";
import JSON5 from 'json5'

import {
  Pane,
  Heading,
  Paragraph,
  SideSheet,
  Button,
  InboxIcon,
  Pill,
} from 'evergreen-ui';
import InboxItem from "./components/layout/InboxItem.js";
import UPDATE_PUBLISHER_MUTATION from "./graphql/mutations/PublisherData/UpdatePublisher.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase_setup/firebase.js";

function App(props) {

  const userid = useSelector((state) => state.user.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [showInbox, setShowInbox] = useState(false)
  const [fbAuth, setFbAuth] = useState(publisherGlobalData.firebaseAuth)


  const [updatePublisher, { updatepublisher }] = useMutation(UPDATE_PUBLISHER_MUTATION);


  const dispatch = useDispatch();

  const currYear = new Date().getFullYear();
  const newNotifCount = inboxmsgs.filter(item => {
    let inboxDate = new Date(publisherGlobalData.inboxDate)
    return new Date(Date.parse(item.date)) > inboxDate
  }).length

  const handleSetInboxDate = () => {
    const tomorrow = new Date().setDate(new Date().getDate() + 1);
    const tomorrowDate = new Date(tomorrow)
    const todayDate = makeDateString(tomorrowDate)
    dispatch(setpublisherGlobalData({ inboxDate: todayDate }));
    updatePublisher(
      {
        variables: {
          id: parseInt(currentpublisher.id),
          changed: JSON.stringify([{ key: "inboxDate", val: todayDate }]),
        }
      });
  }


  useEffect(() => {
    dispatch(setuser(props.user));
    if (props.publisher) {
      dispatch(setcurrent({ id: props.publisher, name: props.publisherName }))
    } else {
      console.log("no pub");
    }
  }, [props.publisherName, props.publisher, props.user, dispatch]);

  const intId = parseInt(userid);
  const noData = !currentpublisher.id || isNaN(intId)

  const createContribCodeArray = codes => {
    let result = codes.map(code => {
      return { label: `${code.value} - ${code.description}`, value: code.id };
    });
    return result;
  }

  useEffect(() => {
    if (!fbAuth && currentpublisher.id) {
      const csrftoken = getCookie('csrftoken');
      fetch('/fetch_firebase_credentials/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken}})

          .then(res => res.json())
          .then(data => {
            let obj = JSON5.parse(data.message);
            signInWithEmailAndPassword(auth, obj.user, obj.pass)
              .then((res) => {
                 return updatePublisher(
                  {
                    variables: {
                      id: parseInt(currentpublisher.id),
                      changed: JSON.stringify([{ key: "firebaseAuth", val: true }]),
                    }
                  });
              })
              .then((message) =>{
                setFbAuth(true)
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
              });
          });
        
    }},[currentpublisher.id]);

  useEffect(()=>{
    if (fbAuth) {
      dispatch(setpublisherGlobalData({ firebaseAuth: true }) )   }
    }

  ,[fbAuth, dispatch])

  const { loading, error, data } = useQuery(QUERY_GLOBAL_DATA, {
    variables: { user: intId, publisher: parseInt(currentpublisher.id) },
    skip: noData
  });

  useEffect(() => {
    if (data) {
      let codeArray = createContribCodeArray(data.onixcontributorcodes);
      dispatch(setcontribcodes(codeArray));
      let onixObj = {
        onixcontributorcodes: data.onixcontributorcodes,
        onixproductforms: data.onixproductforms,
        onixproductformdetails: data.onixproductformdetails,
        onixproductavailabilitys: data.onixproductavailabilitys,
        onixaudiencetypes: data.onixaudiencetypes,
      }
      dispatch(setonixGlobalData(onixObj));
      let publisherObj = {
        created: data.publisher.created,
        inboxDate: data.publisher.inboxDate,
        numproducts: data.products.length,
        publisherimprints: data.publisherimprints,
        publishertags: data.publishertags,
        producttypes: data.producttypes,
        productseriess: data.productseriess,
        publishercreators: data.publishercreators,
        customerclasses: data.customerclasses,
        paymentterms: data.paymentterms,
        shippingtypes: data.shippingtypes,
        salespersons: data.publishersalespersons,
        paymenttypes: data.paymenttypes,
        orderstatuss: data.orderstatuss,
        expensecategorys: data.expensecategorys,
        royaltycategorys: data.royaltycategorys,
        royaltyterms: data.royaltyterms,
        currencys: data.currencys,
        currency: data.publisher.currency,
        accountingMethod: data.publisher.accountingMethod,
        billingId: data.publisher.billingId,
        billingDate: data.publisher.billingDate,
        plan: data.publisher.plan,
        maxProducts: data.publisher.maxProducts,
        firebaseAuth: data.publisher.firebaseAuth,
      }
      dispatch(setpublisherGlobalData(publisherObj));
      if (data.userpublishers && data.userpublishers.length > 0) {
        const currPubId = data.userpublishers[0].publisher.id;
        const currPubName = data.userpublishers[0].publisher.name;
        if (!currentpublisher || !currentpublisher.id) {
          dispatch(setcurrent({ id: currPubId }));
        }
        if (!currentpublisher || !currentpublisher.name) {
          dispatch(setcurrent({ name: currPubName }));
        }
      }
      dispatch(setall(data.userpublishers));
    }
  }, [data, currentpublisher, dispatch]);

  if (loading) return null;
  if (error) return `Error! ${error}`;


  return (!noData && data &&

    <Router>
      <React.StrictMode>
        <Pane>
          <Pane id="outer-container" backgroundColor={"var(--cream)"}>
            <Pane id="left-nav" elevation={1}>
              <Pane id="left-nav-inner">
                <Pane display="flex" flexDirection="column">
                  <Heading id="logo-container"><Link to='/'><img className="logo" src={logo} alt="working lit logo" /></Link></Heading>
                  {props.baseUrl !== "https://workinglit.com" && props.baseUrl !== "https://www.workinglit.com" && process.env.NODE_ENV !== 'development' && <Paragraph size={400} fontWeight="bold" color="#e85cc9">STAGING SERVER!</Paragraph>}
                  <UserMenu pubUser={data.userpublishers[0]} />
                  <SideSheet isShown={showInbox} onCloseComplete={() => setShowInbox(false)} className="background" width="25vw">
                    <Pane display="flex" justifyContent="space-between" alignItems="center" margin={16}>
                      <Paragraph size={500} fontWeight="bolder" color="var(--darkgreen)">INBOX</Paragraph>
                      <Button size="small" disabled={newNotifCount === 0} onClick={e => handleSetInboxDate()}>Mark all as read</Button>
                    </Pane>
                    {inboxmsgs.map(item => {

                      if (!publisherGlobalData.inboxDate) {
                        return (<InboxItem key={`${item.date}_${item.title.replace(/\s/g, "")}`} item={item} unread={true} />)
                      } else {
                        let inboxDate = new Date(publisherGlobalData.inboxDate)
                        if (new Date(Date.parse(item.date)) > inboxDate) { return (<InboxItem key={`${item.date}_${item.title.replace(/\s/g, "")}`} item={item} unread={true} />) }
                        else {
                          return (<InboxItem key={`${item.date}_${item.title.replace(/\s/g, "")}`} item={item} unread={false} />)
                        }

                      }

                    })}

                  </SideSheet>
                  <Button marginTop={4} iconBefore={InboxIcon} className="inbox-button" onClick={e => setShowInbox(!showInbox)}>
                    <Pane display='flex' justifyContent="space-between" alignItems="center">
                      <Paragraph className="inbox-button-text">Inbox</Paragraph>
                      {newNotifCount > 0 && <Pill color={"orange"} marginLeft={16}>{newNotifCount}</Pill>}

                    </Pane>

                  </Button>
                  <Route path='/:page' component={SideBar} />
                  <Route exact path='/' component={SideBar} />
                </Pane>
                <Pane>
                  {process.env.NODE_ENV === 'development' && <Paragraph size={300} color="var(--lightorange)" marginBottom={8}>You are running this app in {process.env.NODE_ENV} mode.</Paragraph>}

                  <Paragraph color="white" fontSize={10} ><a className="link-dark" href="https://docs.google.com/document/d/1_kjfaoJzEogR4GNToaibl4377smPQVXHaO1OAw4UJbI/edit?usp=sharing">Terms of Service</a></Paragraph>
                  <Paragraph size={300} fontSize="10px" color='white'>Copyright &copy; {currYear}</Paragraph>
                  <Paragraph size={300} fontSize="10px" color='white'>Microcosm Publishing</Paragraph>
                </Pane>
              </Pane>
              <Pane id="mobile-nav">
                <Heading id="logo-container"><Link to='/'><img className="logo" src={logo} alt="working-lit-logo" /></Link></Heading>
                <Pane id="mobile-nav-inner">
                  <MobileNav />
                  <UserMenu />
                </Pane>
              </Pane>
            </Pane>

            <Pane id="main-container" elevation={0}>


              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/dashboard" component={Dash} />
                <Route exact path="/user" component={UserSettings} />
                <Route exact path="/publisher" component={PublisherSettings} />
                <Route exact path="/creators" component={Creators}/>
                <Route path={`/creators/:creatorId`} component={Creator}/>
                <Route exact path="/products" component={Products} />
                <Route path={`/products/:productId`} component={Product} />
                <Route exact path="/customers" component={Customers} />
                <Route path={`/customers/:customerId`} component={Customer} />
                <Route exact path="/orders" component={Orders} />
                <Route path={`/orders/:orderId`} component={Order} />
                <Route exact path="/expenses" component={Expenses} />
                <Route path={`/expenses/:expenseId`} component={Expense} />
                <Route exact path="/royalties" component={Royalties} />
                <Route path={`/royalties/:royaltyId`} component={Royalty} />
                <Route exact path="/royaltypayments" component={RoyaltyPayments} />
                <Route component={NotFound} />
              </Switch>
            </Pane>

          </Pane>
        </Pane>
      </React.StrictMode>
    </Router>
  );
}

export default App;
