import { gql } from 'apollo-boost';

const DELETE_ORDER_PAYMENT_MUTATION = gql`
  mutation DeleteOrderPaymentMutation(
    $id: Int!
  ) {
    deleteOrderPayment(
      id: $id
    ) {
      message
    }
  }
`;

export default DELETE_ORDER_PAYMENT_MUTATION;