import { gql } from 'apollo-boost';

const DELETE_EMAIL_MUTATION = gql`
  mutation DeleteEmailMutation(
    $id: Int!,
    $contactType: String!
  ) {
    deleteEmail(
      id: $id,
      contactType: $contactType
    ) {
      message
    }
  }
`;

export default DELETE_EMAIL_MUTATION;