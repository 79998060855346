import { gql } from 'apollo-boost';

const UPDATE_PHONE_MUTATION = gql`
  mutation UpdatePhoneMutation(
    $itemId: Int!,
    $contactType: String!,
    $changed: String!
  ) {
    updatePhone(
      itemId: $itemId,
      contactType: $contactType,
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_PHONE_MUTATION;