import { gql } from 'apollo-boost';

const DELETE_ADDRESS_MUTATION = gql`
  mutation DeleteAddressMutation(
    $id: Int!,
    $contactType: String!
  ) {
    deleteAddress(
      id: $id,
      contactType: $contactType
    ) {
      message
    }
  }
`;

export default DELETE_ADDRESS_MUTATION;