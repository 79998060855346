import { gql } from 'apollo-boost';

const QUERY_GLOBAL_DATA = gql`
  query GlobalData($user: Int!, $publisher: Int!) {
    publisher(id: $publisher) {
      id,
      name,
      currency {
        id, 
        label,
        symbol
      },
      accountingMethod,
      billingId,
      billingDate,
      created,
      plan,
      maxProducts,
      inboxDate,
      firebaseAuth
    }
    userpublishers(user: $user) {
      status,
      firstName,
      lastName,
      photoUrl,
      publisher {
        id,
        name,
        currency {
          id, 
          label,
          symbol
        }
      },
      user {
        id,
        firstName,
        lastName
      },
      role {
        id,
        name
      }
    }
    products(publisher: $publisher) {
      id
    }
    productseriess(publisher: $publisher) {
      id,
      value
    }
    onixcontributorcodes {
      id,
      value,
      description
    }
    onixproductforms {
      id,
      value,
      description
    }
    onixproductformdetails {
      id,
      value,
      description
    }
    onixproductavailabilitys {
      id,
      value,
      description
    }
    onixaudiencetypes {
      id,
      value,
      description
    }
    royaltyterms(publisher: $publisher) {
      id,
      value
    }
    royaltycategorys(publisher: $publisher) {
      id,
      value
    }
    publisherimprints(publisher: $publisher) {
      id,
      value
    }
    publishertags(publisher: $publisher) {
      id,
      name
    }
    publishercreators(publisher: $publisher) {
      id,
      displayName
    }
    publishersalespersons(publisher: $publisher) {
      id,
      value
    }
    paymentterms(publisher: $publisher) {
      id,
      value
    }
    customerclasses(publisher: $publisher) {
      id,
      value
    }
    shippingtypes(publisher: $publisher) {
      id,
      value
    }
    paymenttypes(publisher: $publisher) {
      id,
      value
    }
    orderstatuss(publisher: $publisher) {
      id,
      value
    }
    expensecategorys(publisher: $publisher) {
      id,
      value
    }
    currencys {
      id,
      label,
      symbol
    }
  }
`;

export default QUERY_GLOBAL_DATA;