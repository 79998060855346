import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'react-apollo';
import qs from 'qs';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';
import GroupUser from "./GroupUser.js";
// import GroupInvite from "../Components/GroupInvite.js";
// import GroupUserInvite from "../Components/GroupUserInvite.js";
import Billing from "./Billing.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import QUERY_PUBLISHER_USERS from "../../graphql/queries/QueryPublisherUsers.js";
import UPDATE_PUBLISHER_MUTATION from "../../graphql/mutations/PublisherData/UpdatePublisher.js";
import { getCookie, makeDateString } from '../../util.js';

import {
  Pane,
  Paragraph,
  Card,
  Combobox,
  Table,
  toaster,
} from 'evergreen-ui';

function PublisherSettings(props) {
  const userid = useSelector((state) => state.user.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const dispatch = useDispatch();

  const [pubusers, setPubusers] = useState([]);
  const [userroles, setUserroles] = useState([]);
  const [windowParams, setWindowParams] = useState(qs.parse(window.location.search));
  const intId = parseInt(userid);

  const { loading, error, data } = useQuery(QUERY_PUBLISHER_USERS, {
    variables: { user: intId, publisher: parseInt(currentpublisher.id) },
  });

  const [updatePublisher, { updatepublisher }] = useMutation(UPDATE_PUBLISHER_MUTATION);

  useEffect(() => {
    if (data) {
      setPubusers(data.publisherusers);
      setUserroles(data.userroles);
      // setPubinvites(data.publisherinvitations);
    }
  }, [data]);

  useEffect(() => {
    if (
      windowParams.hasOwnProperty("?checkout_status")
    ) {
      const csrftoken = getCookie('csrftoken');
      let success_info;
      if (windowParams["?checkout_status"] === "success" &&
      windowParams.hasOwnProperty("session_id")){
        success_info = {
          publisherId: currentpublisher.id,
          sessionId: windowParams["session_id"]
        };

      } else if (windowParams["?checkout_status"] === "plan_update"){
        success_info = {
          publisherId: currentpublisher.id,
        };
      }

      fetch('/checkout_success/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify(success_info)
      })
        .then((res) => res.json())
        .then((data) => {
            if(windowParams["?checkout_status"] === "success"){
              setWindowParams({});
              toaster.closeAll();
              toaster.success(`You have successfully subscribed to the ${data.plan}! You can now create and maintain up to ${data.max_products} products.`);
              dispatch(setpublisherGlobalData({ billingId: data.billing_id, plan: data.plan, billingDate: makeDateString(new Date()) , maxProducts: data.max_products}));


            } else if (windowParams["?checkout_status"] === "plan_update"){
              setWindowParams({});
              toaster.closeAll();
              toaster.success(`You have successfully subscribed to the ${data.plan}! You can now create and maintain up to ${data.max_products} products.`);
              dispatch(setpublisherGlobalData({ billingId: data.billing_id, plan: data.plan, maxProducts: data.max_products }));
            }

           else {
            toaster.closeAll();
            toaster.danger("There was an error with your subscription. Please email us at info@workinglit.com.");
            setWindowParams({});
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
        });
    }
  }, [windowParams, currentpublisher.id, dispatch]);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  const handleDeletePublisherUser = puid => {
    // const newpubusers = pubusers.map(item => {
    //   if (item.id !== puid) {
    //     return item;
    //   }
    // })
  }

  // const addNewInvitation = email => {
  //   const newitem = [{ id: 1, invite: { email: email } }];
  //   setPubinvites(pubinvites.concat(newitem));
  // }

  const handleSetCurrency = currency => {
    dispatch(setpublisherGlobalData({ currency: currency }));
    updatePublisher(
      {
        variables: {
          id: parseInt(currentpublisher.id),
          changed: JSON.stringify([{ key: "currency", val: currency }]),
        }
      });
  }

  const handleSetAccounting = method => {
    toaster.closeAll();
    toaster.danger("Changing your accounting method will change all royalties owed.");
    dispatch(setpublisherGlobalData({ accountingMethod: method }));
    updatePublisher(
      {
        variables: {
          id: parseInt(currentpublisher.id),
          changed: JSON.stringify([{ key: "accountingMethod", val: method }]),
        }
      });
  }

  const accountingMethods = [
    { label: 'date items are paid', value: 'paid' },
    { label: 'date items are shipped', value: 'shipped' }
  ];

  const getAccountingMethod = val => {
    return accountingMethods.filter(item => item.value === val)[0];
  }

  return (
    <Pane >
      {/* {!publisherGlobalData.billingActive && publisherGlobalData.billingId && (
        <Alert intent="danger"
          title="Billing Error"
          marginBottom={32}
        >
          There was an error with your latest subscription payment. Please email us at info@workinglit.com to resolve the issue.
        </Alert>
      )} */}
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
        <Breadcrumbs singlePage="HOME" sourceUrl="/dashboard" />
        <Pane marginTop={16} marginBottom={8} size={300} display="flex" justifyContent="space-between">
          <Paragraph marginLeft={4} size={300} fontSize={18} > Publisher Settings for {currentpublisher.name}</Paragraph>
        </Pane>
      </Pane>
      <Pane padding={16}>

        <Pane display="flex" marginTop={16}>
          <Card border="default" padding={16} marginX={16} flex={2} backgroundColor="white">


            <Paragraph size={400} fontWeight="bold" color="#5e5e5e" >ROYALTY ACCOUNTING</Paragraph>
            <Pane marginY={8}>
              <Paragraph size={300} marginBottom={8}>When calculating royalties for product sales during a period, pay based on:</Paragraph>
              <Combobox
                initialSelectedItem={getAccountingMethod(publisherGlobalData.accountingMethod)}
                items={accountingMethods}
                itemToString={item => (item ? item.label : '')}
                onChange={selected => handleSetAccounting(selected.value)}
              />
            </Pane>
            <Paragraph marginTop={16} size={400} fontWeight="bold" color="#5e5e5e" >CURRENCY</Paragraph>
            <Pane marginY={8}>
              <Combobox
                initialSelectedItem={publisherGlobalData.currency}
                items={publisherGlobalData.currencys}
                itemToString={item => (item ? item.label : '')}
                onChange={selected => handleSetCurrency(selected)}
              />
            </Pane>

          </Card>

          <Card border="default" padding={16} marginX={16} flex={1} backgroundColor="white" >
            <Paragraph size={400} fontWeight="bold" color="#5e5e5e" >COLLABORATORS</Paragraph>
            <Table.Body width={800} marginTop={16}>
              <Table.Head height={40} backgroundColor="#F4F5F9">
                <Table.TextCell flexShrink={0} flexGrow={3}>User Email</Table.TextCell>
                <Table.TextCell flexShrink={0} flexGrow={2}>Role</Table.TextCell>
                <Table.TextCell flexShrink={0} flexGrow={2}></Table.TextCell>
              </Table.Head>
              <Table.Body>
                {pubusers.map(item => {
                  return (<GroupUser publisheruser={item} key={item.user.id} userroles={userroles} handleDeletePublisherUser={handleDeletePublisherUser} />)
                })}

                {/* {pubinvites.map(item => {
                  return (<GroupInvite publisherinvite={item} key={`invite${item.id}`} />)
                })} */}
              </Table.Body>
            </Table.Body>
            {/* {pubusers.length < 3 ? <GroupUserInvite userroles={userroles} addNewInvitation={addNewInvitation} /> : */}
              {/* <Paragraph color="var(--robinegg)" marginTop={8} size={300}>Only 2 additional collaborators allowed by default - contact developer to request more. </Paragraph>} */}
                            <Paragraph color="var(--robinegg)" marginTop={8} size={300}>Contact developer@microcosmpublishing.com to request collaborators. </Paragraph>


          </Card>

        </Pane>

        <Card border="default" padding={16} marginTop={16} marginX={16} backgroundColor="white">
          <Paragraph size={400} fontWeight="bold" color="#5e5e5e" >SUBSCRIPTION</Paragraph>

          <Billing />
        </Card>



      </Pane>
    </Pane>
  )
}

export default PublisherSettings;
