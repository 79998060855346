import { gql } from 'apollo-boost';

const QUERY_CUSTOMER = gql`
  query Customer($publisher: Int!, $id: Int!) {
    customer(publisher: $publisher, id: $id) {
      id,
      firstName,
      lastName,
      company,
      outOfBusiness,
      paymentTerms {
        id,
        value
      },
      customerClass {
        id,
        value
      },
      customeremailSet {
        id,
        value,
        primary
      },
      customerphonenumberSet {
        id,
        value,
        primary
      },
      customeraddressSet {
        id,
        line1,
        line2,
        city,
        stateOrRegion,
        country,
        zipcode,
        primary,
        addressType
      },
      orderSet {
        id,
      },
      adminNotes,
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
    }
  }
`;

export default QUERY_CUSTOMER;