import { gql } from 'apollo-boost';

const QUERY_ROYALTY = gql`
  query Royalty($publisher: Int!, $id: Int!) {
    royalty(publisher: $publisher, id: $id) {
      id,
      product {
        id,
        frozen,
        title,
        retailPrice,
        coverImage,
        format {
          id,
          value,
          description
        },
        orderproductSet {
          order {
            id,
            paid
          },
          id,
          product {
            id,
            retailPrice
          },
          quantity,
          discountPerUnit,
          created
        },
        expenseSet {
          id,
          expenseDate,
          amount
        }
      },
      creator {
        id,
        displayName,
        paymentName,
        photoUrl,
        paymentType {
          id,
          value
        },
        creatoremailSet {
          id,
          value,
          primary
        },
        creatoraddressSet {
          id,
          line1,
          line2,
          city,
          stateOrRegion,
          country,
          zipcode,
          primary        }
      }
      royaltylineitemSet {
        id,
        amount,
        thresholdStart,
        thresholdEnd,
        category {
          id,
          value
        },
        royaltyTerm {
          id,
          value
        },
        tierDiscount
      },
      royaltypaymentSet {
        id,
        method {
          id,
          value
        },
        amount,
        sentDate
      }
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
      owed,
      paid
    }
  }
`;

export default QUERY_ROYALTY;