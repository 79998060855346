import { gql } from 'apollo-boost';

const QUERY_PRODUCTS = gql`
  query Products($publisher: Int!, $page: Int, $params: String) {
    products(publisher: $publisher, page: $page, params: $params) {
      id,
      frozen,
      publisher {
        id,
        name
      },
      title,
      subtitle,
      description,
      isbn,
      shipDate,
      publicationDate,
      status {
        id,
        value,
        description
      },
      format {
        id,
        value,
        description
      },
      formatDetail {
        id,
        value,
        description
      },
      retailPrice,
      wholesalePrice,
      inventoryQuantity,
      cartonQty,
      weight,
      width,
      height,
      thickness,
      pageCount,
      imprint {
        id,
        value
      },
      audience {
        id,
        value,
        description
      },
      audienceDetails,
      keySellingPoints,
      marketing,
      adminNotes,
      tags,
      edition,
      volume,
      series {
        id,
        value
      },
      bisac,
      outOfPrint,
      hasShippingCharges,
      coverImage,
      interiorImages,
      comps,
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
      productcreatorSet {
        id,
        creator {
          id,
          displayName
        },
        onixContributorCode {
          id,
          value,
          description
        }
      }
    }
  }
`;

export default QUERY_PRODUCTS;