import React, { useState } from "react";
import { useMutation } from 'react-apollo';
import { makeDateString, checkValidity } from '../../util.js';
import ProductForm from "./ProductForm.js";
import { baseProductObject } from './BaseProductObject.js';

import CREATE_PRODUCT_MUTATION from "../../graphql/mutations/Products/CreateProduct.js";
import CREATE_PRODUCT_CREATOR_MUTATION from "../../graphql/mutations/Products/CreateProductCreator.js";

import {
  Pane,
  Paragraph,
  Button,
  toaster,
} from 'evergreen-ui';

function NewProduct(props) {

  const [staged, setStaged] = useState(baseProductObject);
  const [stagedCreators, setStagedCreators] = useState([]);

  const [createProduct, { productdata }] = useMutation(CREATE_PRODUCT_MUTATION);
  const [createProductCreator, { productcreatordata }] = useMutation(CREATE_PRODUCT_CREATOR_MUTATION);
  const [disableSave, setDisableSave] = useState(true);

  const handleClearStaged = () => {
    setStaged(baseProductObject);
  }


  const canSave = staged.title && staged.format.id && staged.retailPrice


  const handleCreateProduct = async () => {
    let isValid = checkValidity([{ name: "format", required: true, fieldType: "object", field: "value" }], staged);
    if (isValid) {
      let newProduct = { ...staged };
      if (staged.publicationDate) {
        newProduct = { ...newProduct, publicationDate: makeDateString(staged.publicationDate) };
      }
      if (staged.shipDate) {
        newProduct = { ...newProduct, shipDate: makeDateString(staged.shipDate) };
      }

      delete newProduct.productcreatorSet;

      const results = await createProduct(
        {
          variables: {
            publisherId: parseInt(props.publisher),
            product: JSON.stringify(newProduct)
          }
        });

      const productId = results.data.createProduct.message;

      let created = stagedCreators.forEach(creator => {
        createProductCreator(
          {
            variables: {
              product: parseInt(productId),
              creator: parseInt(creator.creator.id),
              onixContributorCode: parseInt(creator.onixContributorCode.id)
            }
          });
      });

      toaster.success("New product created!", { duration: 10 })


      setStagedCreators([]);
      handleClearStaged();
      props.handleToggleNewMode();
      props.handleRefetchProducts();
    } else {
      toaster.danger("Please fix the errors before saving");
    }
  }

  const handleAttachOrUpdateCreator = (id, creator, code) => {
    let newObj = {
      id: id ? id : stagedCreators.length + 1,
      creator: creator,
      onixContributorCode: code
    }
    let newarr = stagedCreators;
    if (id) {
      newarr = newarr.filter(creator => creator.id !== id);
    }
    newarr = [newObj].concat(newarr);
    setStagedCreators(newarr);
  }

  const handleRemoveCreator = creatorId => {
    let newarr = stagedCreators.filter(creator => creator.id !== creatorId);
    setStagedCreators(newarr);
  }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
    if (obj.hasOwnProperty("isValid")) {
      setDisableSave(!obj.isValid);
    }
  }

  return (
    <Pane position="absolute" width="calc(100vw - 204px)">
      <Pane id="statement-form" elevation={3} backgroundColor="white" padding={16}>
        <Pane display="flex" flexDirection="column">
          <Pane display="flex" flexDirection="row" justifyContent="space-between" marginY={8}>
            <Paragraph size={500} fontWeight="bolder" >New Product</Paragraph>

            <Pane display="flex" flexDirection="row">
              <Button disabled={!canSave} marginRight={16} onClick={e => {
                handleCreateProduct();
              }}>
                Save
              </Button>
              <Button onClick={e => {
                handleClearStaged();
                props.handleToggleNewMode();
              }}>
                Cancel
              </Button>

            </Pane>

          </Pane>
          <Pane display="flex" flexDirection="row" width="calc(100vw - 204px)">
            <Pane>
              {/* <CreatorBox
            editing={true}
            onixGlobalData={onixGlobalData} 
            productcreatorSet={stagedCreators}
            handleUpdateCreatorStaged={handleAttachOrUpdateCreator}
            handleDelete={handleRemoveCreator}
            creators={publisherGlobalData.publishercreators}
            productId={undefined}
            editMode={"edit"}
            newMode={true}
          /> */}
            </Pane>
            <ProductForm
              staged={staged}
              handleUpdateStaged={handleUpdateStaged}
              editing={true}
              editMode="new"
            />

          </Pane>
        </Pane></Pane></Pane>
  )
}

export default NewProduct;