import { Link, useParams } from 'react-router-dom';

import {
    Pane,
    Paragraph,
    HomeIcon,
    PersonIcon,
    BoxIcon,
    ShopIcon,
    DollarIcon,
    BankAccountIcon,
    DrawIcon,
    PercentageIcon,
} from 'evergreen-ui';


function SideBar() {
    const page = useParams().page || 'dashboard';
    return (<Pane display="flex" flexDirection="column" marginTop={8}>
        <Paragraph className={page === 'dashboard' ? 'nav-button-selected' : 'nav-button'}><Link to='/dashboard'><HomeIcon size={16} marginRight={10} />Home</Link></Paragraph>
        <Paragraph className={page === 'products' ? 'nav-button-selected' : 'nav-button'}><Link to='/products'><DrawIcon size={16} marginRight={10} />Products</Link></Paragraph>
        <Paragraph className={page === 'orders' ? 'nav-button-selected' : 'nav-button'}><Link to='/orders'><BoxIcon size={16} marginRight={10} />Orders</Link></Paragraph>
        <Paragraph  className={page === 'customers' ? 'nav-button-selected' : 'nav-button'}><Link to='/customers'><ShopIcon size={16} marginRight={10} />Customers</Link></Paragraph>
        <Paragraph  className={page === 'creators' ? 'nav-button-selected' : 'nav-button'}><Link to='/creators'><PersonIcon size={16} marginRight={10} />Creators</Link></Paragraph>
        <Paragraph className={page === 'royalties' ? 'nav-button-selected' : 'nav-button'}><Link to='/royalties'><PercentageIcon size={16} marginRight={10} />Royalties</Link></Paragraph>
        <Paragraph  className={page === 'royaltypayments' ? 'nav-button-selected' : 'nav-button'}><Link to='/royaltypayments'><DollarIcon size={16} marginRight={10} />Payments</Link></Paragraph>
        <Paragraph  className={page === 'expenses' ? 'nav-button-selected' : 'nav-button'}><Link to='/expenses'><BankAccountIcon size={16} marginRight={10} />Expenses</Link></Paragraph>
    </Pane>);
}

export default SideBar;