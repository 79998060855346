import { gql } from 'apollo-boost';

const CREATE_ROYALTY_PAYMENT_MUTATION = gql`
  mutation CreateRoyaltyPaymentMutation(
    $royaltyId: Int!,
    $payment: String!
  ) {
    createRoyaltyPayment(
      royaltyId: $royaltyId,
      payment: $payment
    ) {
      message
    }
  }
`;

export default CREATE_ROYALTY_PAYMENT_MUTATION;