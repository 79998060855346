import { gql } from 'apollo-boost';

const CREATE_ORDERSTATUS_MUTATION = gql`
  mutation CreateOrderStatusMutation(
    $publisherId: Int!,
    $orderStatus: String!
  ) {
    createOrderStatus(
      publisherId: $publisherId,
      orderStatus: $orderStatus
    ) {
      message
    }
  }
`;

export default CREATE_ORDERSTATUS_MUTATION;