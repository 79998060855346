import React, { useState } from "react";
import { useMutation } from 'react-apollo';

// ------- MUTATIONS
import CREATE_ROYALTY_MUTATION from "../../graphql/mutations/Royaltys/CreateRoyalty.js";
import CREATE_ROYALTY_LINE_ITEM_MUTATION from "../../graphql/mutations/Royaltys/CreateRoyaltyLineItem.js";


// ------- COMPONENTS
import { baseRoyaltyObject } from './BaseRoyaltyObject.js';
import RoyaltyLineItem from "./RoyaltyLineItem.js";
import {NotSeeingItems} from "../../components/NotSeeingItems.js";
import { SelectCreator } from "../../inputs/index.js";

import {
  Pane,
  Paragraph,
  TextInput,
  Button,
  Card,
  Table,
  AddIcon,
  ArrowDownIcon,
  toaster,
  SelectMenu,
  Spinner,
} from 'evergreen-ui';

function NewRoyaltyForm({handleToggleNewMode, handleRefetchRoyaltys, data, publisher}) {
  const [staged, setStaged] = useState(baseRoyaltyObject);
  const [productSet, setProductSet] = useState([])
  const [canSave, setCanSave] = useState(false);
  const [items, setItems] = useState([{
    tmpid: 1,
    amount: "",
    thresholdStart: "",
    thresholdEnd: "",
    royaltyTerm: "",
    category: { id: 1, value: "units sold" },
    tierDiscount: 'None',
    tmpids: []
  }
  ]);
  const [idCount, setIdCount] = useState(2)


  const [createRoyalty, { royaltydata }] = useMutation(CREATE_ROYALTY_MUTATION);
  const [createRoyaltyLineItem, { itemdata }] = useMutation(CREATE_ROYALTY_LINE_ITEM_MUTATION);


  const handleClearStaged = () => {
    setStaged(baseRoyaltyObject);
  }

  const handleCreateRoyalty = async () => {
    let newStaged = JSON.parse(JSON.stringify(staged));
    if (newStaged.hasOwnProperty("royaltylineitemSet")) {
      delete newStaged.royaltylineitemSet;
    }
    if (newStaged.hasOwnProperty("royaltypaymentSet")) {
      delete newStaged.royaltypaymentSet;
    }
    const results = await createRoyalty(
      {
        variables: {
          publisherId: parseInt(publisher),
          royalty: JSON.stringify(newStaged)
        }
      });
    const allCreated = await Promise.all(items.map(async (item) => {
      let royaltyItem = {
        amount: item.amount,
        thresholdStart: item.thresholdStart,
        thresholdEnd: item.thresholdEnd,
        royaltyTerm: item.royaltyTerm,
        category: item.category,
        tierDiscount: item.tierDiscount,
      }
      const created = await createRoyaltyLineItem(
        {
          variables: {
            royaltyId: parseInt(results.data.createRoyalty.message),
            royaltyItem: JSON.stringify(royaltyItem)
          }
        }
      )
      return created

    }))
    toaster.closeAll()
    toaster.success(`Created a new royalty for ${staged.creator.displayName} for ${staged.product.title} with ${allCreated.length} tiers`, { duration: 10 })

    handleClearStaged();
    handleToggleNewMode();
    handleRefetchRoyaltys();
  }
  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
  }

  const handleAttachProduct = productString => {
    let product = JSON.parse(productString)

    handleUpdateStaged({
      "key": "product",
      "val": product
    })
    checkIfValid(items, true)
  }

  const handleAttachCreator = creatorString => {
    let creator = JSON.parse(creatorString)
    let existingRoyalties = creator.royaltySet.map(royalty => royalty.product.id)
    let productSet = creator.productcreatorSet.filter(item => !existingRoyalties.includes(item.product.id) && !item.product.frozen).map(item => item.product)
    setProductSet(productSet)

    handleUpdateStaged({
      "key": "creator",
      "val": creator
    })

    handleUpdateStaged({
      "key": "product",
      "val": ""
    })
    checkIfValid(items, false)
  }

  const handleAddItem = (category, discount) => {
    const lastTier = items.filter(item => item.last === true).filter(item => item.tierDiscount === discount)[0]
    let tmpid = idCount;
    let newarr = [{
      tmpid: tmpid + 1,
      amount: "",
      thresholdStart: lastTier ? lastTier.thresholdStart + 100 : 0,
      thresholdEnd: "",
      royaltyTerm: "",
      category: category,
      tierDiscount: discount,
      tmpids: []
    }].concat(items);
    tmpid += 1;
    setIdCount(tmpid);
    setEndThresholds(newarr, discount)
    setItems(newarr);
    checkIfValid(newarr);
  }

  const handleUpdateItem = item => {
    let newarr = items.filter(ri => ri.tmpid !== item.tmpid);
    newarr.push(item);
    let disc = item.tierDiscount;
    setEndThresholds(newarr, disc)
    setItems(newarr);
    checkIfValid(newarr);
  }

  const handleRemoveItem = lineitem => {
    let newarr = [].concat(items);
    newarr = newarr.filter(item => item.tmpid !== lineitem.tmpid);
    setEndThresholds(newarr, lineitem.tierDiscount)
    setItems(newarr);
    checkIfValid(newarr)
  }

  const setEndThresholds = (itemarr, d) => {
    let discarr = itemarr.filter(item => item.tierDiscount === d)
    discarr.sort((a, b) => (a.tmpid > b.tmpid) ? 1 : ((b.tmpid > a.tmpid) ? -1 : 0))
    discarr.forEach((item, index) => {
      if (index === 0) {
        item.thresholdStart = 0;
      }
      else if (index > 0) {
        discarr[index - 1].thresholdEnd = String(item.thresholdStart - 1)
      }
      if (index === discarr.length - 1) {
        item.thresholdEnd = "\u{221E}"
      }
    })
    discarr.forEach((item, index) => {
      if (index > 0) {
        item.invalid = item.thresholdStart <= discarr[index - 1].thresholdStart

      }

    })
  }

  const sortItems = (arr, category) => {
    arr = arr.filter(item => item.category.id === category)
    let discountList = sortItemsByDiscount(arr)

    let newarr = [];
    discountList.forEach((d, index) => {
      let discarr = arr.filter(item => item.tierDiscount === d)
      discarr.sort((a, b) => (a.tmpid > b.tmpid) ? 1 : ((b.tmpid > a.tmpid) ? -1 : 0))
      discarr.forEach((item) => { item.first = false; item.last = false; item.tierCount = discarr.length; })

      discarr[0].first = true;
      discarr[discarr.length - 1].last = true;
      newarr = newarr.concat(discarr)
    })

    return newarr
  }

  const sortItemsByDiscount = arr => {
    let discountList = arr.map(item => item.tierDiscount).filter((v, i, a) => a.indexOf(v) === i);
    let discountTmpids = [];

    discountList.forEach((d) => {
      let temparr = arr.filter(item => item.tierDiscount === d)
      let tmpids = temparr.map(item => item.tmpid)
      temparr.forEach(item => { item.tmpids = tmpids })
      discountTmpids.push({ discount: d, tmpid: Math.min(...tmpids), tmpids: tmpids })
    })

    discountTmpids = discountTmpids.sort((a, b) => (a.tmpid > b.tmpid) ? 1 : ((b.tmpid > a.tmpid) ? -1 : 0))
    discountList = discountTmpids.map(item => item.discount)

    return discountList

  }


  const checkIfValid = (items, prodAttached) => {
    const invalidTier = items.some(item => {
      return (!item.royaltyTerm || item.invalid || !item.amount)
    }) || items.length < 1

    let invalid;

    if (prodAttached === undefined) {
      invalid = !staged.product.id || invalidTier
    } else {
      invalid = invalidTier || !prodAttached
    }

    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }

  const handleChangeDiscount = e => {
    let tmpids = items.filter(a => a.tmpid.toString() === e.target.id)[0].tmpids
    tmpids.forEach((id) => {
      items.filter(a => a.tmpid === id)[0].tierDiscount = e.target.value
    })
  }

  return (
    <Pane backgroundColor="var(--background)" width="100%" height="100%">
      <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingBottom={8} paddingTop={16} width="100%" display="flex" justifyContent="space-between" alignItems="flex-end">
        <Paragraph fontSize={18}> New Royalty</Paragraph>
        <Pane display="flex" flexDirection="row" justifyContent="flex-start">
          <Button disabled={!canSave || royaltydata?.loading || itemdata?.loading} marginRight={16} className="confirm-button" onClick={e => {
            handleCreateRoyalty();
          }}>
            CREATE ROYALTY
            {(royaltydata?.loading || itemdata?.loading) && <Spinner marginLeft={8} size={12}/>}


          </Button>
          <Button onClick={e => {
            handleClearStaged();
            handleToggleNewMode();
          }}>
            Cancel
          </Button>
        </Pane>

      </Pane>
      <Pane padding={16}>
        <Card backgroundColor="white" padding={16} elevation={1}>
          <Pane display="flex" flexDirection="row" alignItems="flex-end" justifyContent="flex-start">
            <Pane marginY={16} >
              <Paragraph color="#667b7f" size={300}>Creator</Paragraph>

              <SelectCreator handleNewCreator={handleAttachCreator} parent="royalties" royalties={data} />


            </Pane>

            <Pane marginY={16} marginLeft={40}>
              <Paragraph color="#667b7f" size={300}>Product </Paragraph>
              <Pane >
                <SelectMenu
                  title=""
                  titleView={({ close, headerHeight }) => {
                    return (
                      <NotSeeingItems headerHeight={headerHeight} close={close} items="products" parentItem="royalties" />
                    )
                  }}
                  options={
                    productSet.length ? 
                    productSet.filter(product => !product.frozen).reduce((acc, obj) => {
                      if (!acc.some(o => o.id === obj.id)) {
                        acc.push(obj);
                      }
                      return acc;
                    }, []).map((product) => ({ key: `product${product.id}`, label: `${product.title} (${product.format ? product.format.description : "No Format"})`, value: JSON.stringify(product) })) : []
                  }
                  selected={staged.product.title ? `${staged.product.title} (${staged.product.format.description})` : 'Select Product...'}
                  onSelect={(option) => {
                    handleAttachProduct(option.value);
                  }}
                  closeOnSelect>
                  <Button disabled={!staged.creator.id} >{staged.product.title ? `${staged.product.title} (${staged.product.format.description})` : 'Select Product...'}</Button>
                </SelectMenu>
              </Pane>

            </Pane>
          </Pane>
          <Pane>
            <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>REGULAR TIERS</Paragraph>

            <Table width={600}>
              <Table.Head height={40} className="table-heading">
                <Table.HeaderCell flex={2}>
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell flex={4}>
                  Term
                </Table.HeaderCell>
                <Table.HeaderCell flex={5} >
                  Tier Start/End
                </Table.HeaderCell>

                <Table.HeaderCell flex={1} >
                </Table.HeaderCell>

              </Table.Head>
              {sortItems(items, 1).map(item => {
                return (
                  <RoyaltyLineItem key={`royaltyline${item.tmpid}`} item={item} handleUpdateItem={handleUpdateItem} editing={true} handleDelete={handleRemoveItem} />
                );
              })}
            </Table>
            <Button
              iconBefore={AddIcon}
              onClick={e => handleAddItem({ id: 1, value: "units sold" }, "None")}
              size="small"
              marginTop={8}>
              ADD TIER
            </Button>            <Pane >
              <Paragraph size={400} fontWeight="bold" color="#5e5e5e" marginTop={16} marginBottom={8}>DISCOUNTED SALES TIERS</Paragraph>
              {sortItems(items, 2).length > 0 &&
                <Table width={600}>
                  <Table.Head height={40} className="table-heading">
                    <Table.HeaderCell flex={2}>
                      Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell flex={4}>
                      Term
                    </Table.HeaderCell>
                    <Table.HeaderCell flex={5} >
                      Tier Start/End
                    </Table.HeaderCell>

                    <Table.HeaderCell flex={1} >
                    </Table.HeaderCell>

                  </Table.Head>
                  {sortItems(items, 2).map(item => {
                    if (item.first) {
                      return (
                        <Pane>
                          <Table.Row height={36} backgroundColor="var(--offwhite)" >
                            <Table.TextCell flex={1}>
                              <Paragraph size={300} marginLeft={16} fontWeight="bold" color="var(--darkgrey)">
                                If order discounted by at least
                                <TextInput
                                  className="item-form"
                                  width={50}
                                  marginY={0} id={item.tmpid} placeholder={item.tierDiscount} onChange={e => handleChangeDiscount(e)} marginLeft={10} />
                                %:</Paragraph>
                            </Table.TextCell>
                            <Table.TextCell flex={1}>
                              <Button size="small" iconBefore={ArrowDownIcon} onClick={e => handleAddItem({ id: 2, value: "discount" }, item.tierDiscount)}>
                                Add tier for sales discounted by {item.tierDiscount} %
                              </Button>
                            </Table.TextCell>
                          </Table.Row>
                          <RoyaltyLineItem key={`royaltyline${item.tmpid}`} item={item} handleUpdateItem={handleUpdateItem} editing={true} handleDelete={handleRemoveItem} />
                        </Pane>
                      )
                    }

                    else {
                      return (
                        <RoyaltyLineItem key={`royaltyline${item.tmpid}`} item={item} handleUpdateItem={handleUpdateItem} editing={true} handleDelete={handleRemoveItem} />
                      );
                    }
                  })}
                </Table>}
              <Button iconBefore={AddIcon} size="small"
                marginTop={8} onClick={e => handleAddItem({ id: 2, value: "discount" }, " ")}>ADD DISCOUNTED TIER SET</Button>
            </Pane>

          </Pane>

        </Card>

      </Pane>


    </Pane>
  )
}

export default NewRoyaltyForm;