import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { commaSepThou, getCookie } from '../../util.js';

// ------- QUERIES
import QUERY_PRODUCTS from "../../graphql/queries/Products/QueryProducts.js";

// ------- COMPONENTS
import LandingPage from "../../components/layout/LandingPage.js";
import NewProduct from "./NewProduct.js";
import CsvUpload from "../../components/layout/CsvUpload.js";

import {
  ForeignKeyMultiSelectDropDownInput,
} from "../../inputs/index.js";

import {
  Pane,
  AddIcon,
  DocumentShareIcon,
  LockIcon,
  Text,
  Badge,

} from 'evergreen-ui';
import CloudImage from "../../components/CloudImage.js";

function Products() {
  // ------- STATES
  const location = useLocation();
  const history = useHistory();

  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const onixGlobalData = useSelector((state) => state.onixGlobalData.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [pagecount, setPagecount] = useState(0);
  const [currpage, setCurrpage] = useState(1);

  const [newmode, setNewmode] = useState(false);
  const [csvmode, setCsvmode] = useState(false);

  const [params, setParams] = useState({});
  const [sourcePageInfo, setSourcePageInfo] = useState(false);


  const cantCreateNew = publisherGlobalData.billingId
    ? publisherGlobalData.maxProducts === "unlimited" ? false :
      parseInt(publisherGlobalData.maxProducts) > count
        ? false
        : true
    : 10 > count
      ? false
      : true;

  // ------- HOOKS

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_PRODUCTS, {
    variables: { publisher: parseInt(currentpublisher.id), page: parseInt(currpage), params: JSON.stringify(params) },
  });

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  useEffect(() => {
    if (data) {
      setProducts(data.products);
      const csrftoken = getCookie('csrftoken');
      fetch('/count_products/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify({ publisher: currentpublisher.id })
      })
        .then(res => res.json())
        .then(data => {
          setCount(parseInt(data.message));
          setPagecount(Math.ceil(data.message / 25));
        })
      history.replace({
        search: "",
      });
    }
  }, [data, history, currentpublisher.id]);

  useEffect(() => {
    if (location.state) {
      let locState = location.state;
      setSourcePageInfo(locState)
    }
  }, [location.state]);

  // ------- FUNCTIONS

  const handleRefetchProducts = () => {
    setParams({});
    refetch();
  }

  const handleToggleNewMode = () => {
    setNewmode(!newmode);
  }

  const handleToggleCsvMode = () => {
    setCsvmode(!csvmode);
    refetch();
  }

  // ------- INPUTS

  const buttons = [
    { title: "NEW PRODUCT", icon: AddIcon, onClick: setNewmode },
    { title: "IMPORT PRODUCTS", icon: DocumentShareIcon, onClick: setCsvmode },
  ];

  const cols = [
    { title: "PRODUCT TITLE", type: "button", flex: "5", query: "title" },
    { title: "FORMAT", type: "button", flex: "2", query: "format__description" },
    { title: "PUB DATE", type: "button", flex: "2", query: "publication_date" },
    { title: "RETAIL", type: "button", flex: "2", query: "retail_price", align: "right" },
    { title: "INVENTORY", type: "button", flex: "2", query: "inventory_quantity", align: "right" },
    { title: "IMPRINT", type: "button", flex: "2", query: "imprint__value" },
    { title: "STATUS", type: "button", flex: "2", query: "status__description" },
  ];

  const searchInit = {
    title: "",
    subtitle: "",
    isbn: "",
    creator_payment_name: "",
    imprint__value: "",
    format__value: "",
    status__value: "",
    exclude: {}
  }

  const searchInputs = {
    title: { searchq: "title", dropLabel: "By Product Title", },
    isbn: { searchq: "isbn", dropLabel: "By Product ISBN", },
    creator_payment_name: { searchq: "creator_payment_name", dropLabel: "By Creator Name", },
  }

  const filterInputs = {
    imprint__value: { comp: ForeignKeyMultiSelectDropDownInput, formLabel: "IMPRINTS", group: 1, values: publisherGlobalData.publisherimprints, marginRight: 4 },
    format__value: { comp: ForeignKeyMultiSelectDropDownInput, formLabel: "FORMATS", group: 1, values: onixGlobalData.onixproductforms, marginRight: 4 },
    status__value: { comp: ForeignKeyMultiSelectDropDownInput, formLabel: "STATUSES", group: 1, values: onixGlobalData.onixproductavailabilitys, marginRight: 4 },
  }

  const csvFields = [
    "adminNotes",
    "audience",
    "audienceDetails",
    "bisac",
    "cartonQty",
    "created",
    "description",
    "edition",
    "format",
    "formatDetail",
    "hasShippingCharges",
    "height",
    "id",
    "imprint",
    "inventoryQuantity",
    "isbn",
    "keySellingPoints",
    "marketing",
    "modified",
    "outOfPrint",
    "pageCount",
    "productcreatorSet",
    "publisher",
    "publicationDate",
    "retailPrice",
    "series",
    "shipDate",
    "status",
    "subtitle",
    "thickness",
    "title",
    "volume",
    "weight",
    "wholesalePrice",
    "width"
  ];

  const csvConfig = {
    "series": ["value"],
    "imprint": ["value"],
    "format": ["description"],
    "formatDetail": ["description"],
    "width": ["value", "unit"],
    "weight": ["value", "unit"],
    "thickness": ["value", "unit"],
    "height": ["value", "unit"],
    "status": ["description"],
    "audience": ["description"],
    "publisher": ["name"],

    "primarySets": {},
    "sets": {
      "productcreatorSet": {
        "fields": ["creator"],
        "subfields": { "creator": "displayName" },
        "label": "creators"
      }
    },
    "arrays": {
      "bisac": ','
    }

  }

  // ------- CSV Stuff


  // if you make changes to these arrays, be sure to update the lists in views.py as well
  const csvTemplate = [
    [
      "title",
      "format", // the exact name, as listed in WorkingLit
      "edition",
      "subtitle",
      "creators", // A comma-separated list of the WorkingLit creator ID optionally followed the Onix creator type in parentheses
      "description",
      "volume",
      "series", // the exact name of the series, as it exists (or should exist) in WorkingLit
      "bisac", // A comma-separated list of up to 4 codes
      "isbn",
      "format_detail", // the exact name, as listed in WorkingLit
      "page_count",
      "weight", // a number followed by the unit code as listed in WorkingLit
      "width", // a number followed by the unit code as listed in WorkingLit
      "height", // a number followed by the unit code as listed in WorkingLit
      "thickness", // a number followed by the unit code as listed in WorkingLit
      "retail_price", // numbers and periods only, e.g. 12.99
      "inventory_quantity",
      "status", // the exact name, as listed in WorkingLit
      "out_of_print", // true or false
      "publication_date", // yyyy-mm-dd
      "ship_date", // yyyy-mm-dd
      "carton_qty",
      "digital_product", // true or false
      "imprint", // the exact name of the series, as it exists (or should exist) in WorkingLit
      "audience", // the exact name, as listed in WorkingLit
      "audience_details",
      "admin_notes",
      "key_selling_points",
      "marketing"
    ],
    [
      "",
      "Onix Product Form Description e.g. Hardcover",
      "Integers only",
      "",
      "A semicolon-separated list of each creator's display name - Onix creator type will default to 'By (author)' and can be modified later",
      "",
      "Integers only",
      "The exact name of the series as it exists (or should exist) in WorkingLit",
      "A semicolon-separated list of up to 4 codes",
      "",
      "If hardcover: 'With dust jacket' or 'Paper over boards'",
      "Integers only",
      "Numbers and periods only (unit matches account settings)",
      "Numbers and periods only (unit matches account settings)",
      "Numbers and periods only (unit matches account settings)",
      "Numbers and periods only (unit matches account settings)",
      "Numbers and periods only; e.g. 12.99",
      "Integers only",
      "Onix Publishing Status",
      "true or false",
      "yyyy-mm-dd",
      "yyyy-mm-dd",
      "Integers only",
      "true if ebook or audiobook download",
      "The exact name of the imprint as it exists (or should exist) in WorkingLit",
      "Onix Audience Type",
      "Age range",
      "",
      "",
      ""
    ],
    [
      "Peepers",
      "Hardcover", // the exact name, as listed in WorkingLit
      "",
      "An Adventure",
      "Charles Dickens; Jane Austen",
      "A psychedelic space romp about love and ego.",
      "",
      "A Peepers Adventure",
      "CGN000000; CGN004070",
      "9781683963967",
      "", // the exact name, as listed in WorkingLit
      "205",
      "1.5", 
      "8.9", 
      "11.3", 
      "1",
      "39.99", // numbers and periods only, e.g. 12.99
      "400",
      "Active", // the exact name, as listed in WorkingLit
      "false", // true or false
      "2021-02-26", // yyyy-mm-dd
      "2021-02-26", // yyyy-mm-dd
      "12",
      "true", // true or false
      "", // the exact name of the series, as it exists (or should exist) in WorkingLit
      "General/trade", // the exact name, as listed in WorkingLit
      "18-30",
      "",
      "Similar to Vaughn Bodé and Ralph Bakshi",
      ""
    ]
  ];

  const csvWidths = [
    3,
    2,
    2,
    3,
    4,
    10,
    2,
    2,
    2,
    3,
    3,
    2,
    2,
    2,
    2,
    2,
    2,
    3,
    2,
    3,
    3,
    2,
    2,
    4,
    3,
    3,
    3,
    4,
    4,
    4
  ]

  if (error) return `Error! ${error}`;

  return (
    <Pane position="absolute" padding={16} width="100%" height="100%">
      <Pane >
        {newmode ? (
          <NewProduct
            handleToggleNewMode={handleToggleNewMode}
            handleRefetchProducts={handleRefetchProducts}
            publisher={currentpublisher.id}
          />
        ) : csvmode ? (
          <CsvUpload
            handleToggleCsvMode={handleToggleCsvMode}
            template={csvTemplate}
            itemType="product"
            title="Products"
            tableWidth={5000}
            colWidths={csvWidths} />
        ) : (
          <Pane>
            <LandingPage
              title="Products"
              location={location}
              sourcePageInfo={sourcePageInfo}
              data={products}
              loading={loading}
              buttons={buttons}
              count={count}
              pagecount={pagecount}
              searchInputs={searchInputs}
              filterInputs={filterInputs}
              searchInit={searchInit}
              csvConfig={csvConfig}
              csvFields={csvFields}
              csvType="Products"
              cols={cols}
              refetch={refetch}
              setParams={setParams}
              RowComponent={ProductRow}
              currpage={currpage}
              setCurrpage={setCurrpage}
              cantCreateNew={cantCreateNew}
            />
          </Pane>
        )}
      </Pane>
    </Pane>
  )
}

export default Products;

function ProductRow({ item, allmode, handleCheck, colWidths, makeIDCell, makeTableCellArray }) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const [checked, setChecked] = useState(false);

  const handleSetChecked = () => {
    handleCheck(checked, item)
    setChecked(!checked);
  }

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  const rowCells = [
    {
      content: makeIDCell(checked, handleSetChecked, item.id), disableSelect:true
    },
    {
      content: <Pane display="flex" alignItems="center">
        <CloudImage path={item.coverImage ? item.coverImage : ""} customClass=" small" />
        <Pane marginLeft={16}>
          {item.frozen && <LockIcon size={12} color="muted" />}

          <Text size={300} className="breakable block"><Link to={`/products/${item.id}`}>{item.title}{item.subtitle && `: ${item.subtitle}`}</Link></Text>
          {item.isbn && <Text size={300} className="block" color="grey">ISBN {item.isbn}</Text>}
        </Pane>
      </Pane>
    },
    { content: <Badge className="badge" color="neutral">{item.format ? item.format.description : "No Format"}</Badge> },
    { content: item.publicationDate },
    { isNumber: true, content: <Pane>{currency}{item.retailPrice}</Pane> },
    { isNumber: true, content: item.inventoryQuantity && commaSepThou(item.inventoryQuantity) },
    {
      content: <Text className="breakable" size={300}>{item.imprint ? item.imprint.value : ""}</Text>
    },

    { content: item.status ? item.status.description : "" },


  ]

  useEffect(() => {
    setChecked(allmode);
  }, [allmode]);
  const cellArray = makeTableCellArray(rowCells, colWidths, item.id)

  return cellArray
}