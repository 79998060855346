import React, { useState } from "react";
import JSON5 from 'json5';
import { useMutation } from 'react-apollo';
import { useSelector } from 'react-redux';
import { makeInputs, makeAdjustedString, makeDateString } from '../../util.js';

import CREATE_ROYALTY_PAYMENT_MUTATION from "../../graphql/mutations/Royaltys/CreateRoyaltyPayment.js";

import {
  Pane,
  Paragraph,
  Badge,
  TextInputField,
  Button,
  Table,
  toaster
} from 'evergreen-ui';

import {DateFieldInput} from "../../inputs/index.js";

import {ForeignKeyDropDownInput} from "../../inputs/index.js";

function NewBalancePaymentForm(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const paymentsInitial = props.selected.map(royalty => {
    const adjustedStatementString = makeAdjustedString(royalty.statementInfo)
    const statementInfo = JSON5.parse(adjustedStatementString)
    const lifetimeBalance = (statementInfo.lifeCredit - statementInfo.lifeDebit) - parseFloat(royalty.paid)
    const product = JSON5.parse(makeAdjustedString(royalty.product))
    return { id: royalty.id, product: product, amount: lifetimeBalance }
  })


  const [staged, setStaged] = useState({ sentDate: "", method: { id: "", value: "" } });
  const [canSave, setCanSave] = useState(false);
  const [payments, setPayments] = useState(paymentsInitial);

  const [createRoyaltyPayment, { royaltydata }] = useMutation(CREATE_ROYALTY_PAYMENT_MUTATION);

  const handleClearStaged = () => {
    setStaged({ sentDate: "", method: { id: "", value: "" } });
  }
  const total = `$${payments.map(item => item.amount).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)}`

  const handleCreateRoyaltyPayment = async () => {

    const allPayments = await Promise.all(payments.map(async (item) => {
      const royaltyId = item.id
      const payment = {
        method: { id: staged.method.id },
        sentDate: makeDateString(staged.sentDate),
        amount: item.amount.toFixed(2)
      }
      const paymentSaved = await createRoyaltyPayment(
        {
          variables: {
            royaltyId: royaltyId,
            payment: JSON.stringify(payment)
          }
        }
      )
      return paymentSaved
    }))
    toaster.closeAll()
    toaster.success(`Made ${allPayments.length} payments totaling ${total} to ${props.creator.paymentName ? props.creator.paymentName : props.creator.displayName}`, { duration: 10 })

    props.handleTogglePaymentMode();
    handleClearStaged();
    props.handleRefetchRoyaltys();
  }

  const handleUpdateStaged = obj => {
    let newobj = { ...staged, [obj.key]: obj.val }
    setStaged(newobj);
    checkIfValid(newobj);
  }

  const checkIfValid = arr => {
    let invalid = false;
    if (!arr.method) {
      invalid = true;
    }
    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }
  const handleChangeOwed = (newAmount) => {
    let payArray = payments.filter(item => item.id !== newAmount.id)
    if (newAmount.amount === "") {
      newAmount.amount = paymentsInitial.filter(li => li.id === newAmount.id)[0].amount
    } else {
      newAmount.amount = parseFloat(newAmount.amount)
    }
    payArray.push(newAmount)
    setPayments(payArray)
  }

  const inputs = {
    method: { width: 200, comp: ForeignKeyDropDownInput, formLabel: "Payment Method:", handleChange: handleUpdateStaged, group: 1, values: publisherGlobalData.paymenttypes },
    sentDate: { width: 300, comp: DateFieldInput, formLabel: "Date Sent:", handleChange: handleUpdateStaged, group: 2 },
  }

  const formInputs = makeInputs(staged, inputs, true);


  return (
    <Pane position="absolute" width="calc(100vw - 204px)" display="flex" justifyContent="center">
      <Pane elevation={3} backgroundColor="white" paddingY={16} paddingX={32} height="calc(100vh - 32px)">
        <Pane display="flex" justifyContent="space-between">
          <Paragraph size={500} fontWeight="bolder" >Create Royalty Payments for Statement Period: {props.start} - {props.end}</Paragraph>

          <Pane marginLeft={64} display="flex" flexDirection="row" justifyContent="flex-start">
            <Button className="confirm-button" disabled={!canSave} marginRight={16} onClick={e => {
              handleCreateRoyaltyPayment();
            }}>
              CREATE PAYMENTS
            </Button>
            <Button onClick={e => {
              handleClearStaged();
              props.handleTogglePaymentMode();
            }}>
              Cancel
            </Button>
          </Pane>
        </Pane>

        <Pane marginTop={32} display="flex" flexDirection="row">
          <Pane marginTop={8}>
            <Paragraph size={400} fontWeight="bold" color="#5e5e5e" >CREATOR</Paragraph>
            <Paragraph>
              {props.creator.displayName} {props.creator.paymentName && `(${props.creator.paymentName})`}
            </Paragraph>

          </Pane>
          <Pane marginLeft={30}> {formInputs.group1}</Pane>
          <Pane marginLeft={30}> {formInputs.group2}</Pane>
          <Pane marginLeft ={30} >
          <Paragraph fontWeight="bold">Total Payment to be Recorded:</Paragraph>
          <Paragraph marginLeft={10}>{total}</Paragraph>
        </Pane>
        </Pane>
        <Pane marginTop={30}>
          <Table.Head height={32} backgroundColor="#F4F5F9" >
            <Table.TextCell>Product</Table.TextCell>
            <Table.TextCell>Balance Due</Table.TextCell>
            <Table.TextCell>Amount to Pay?</Table.TextCell>

          </Table.Head>
          {paymentsInitial.map(item => {
            return (
              <Table.Row className="table-row" value="">
                <Table.TextCell > {item.product.title}
                  <Badge marginLeft={5}>{item.product.format ? item.product.format.description : "(No Format)"}</Badge>
                </Table.TextCell>
                <Table.TextCell >{item.amount.toFixed(2)}</Table.TextCell>
                <Table.TextCell >
                  <TextInputField marginTop={8} label=""
                    onChange={e => handleChangeOwed({ id: item.id, amount: e.target.value })}
                    placeholder={String(item.amount.toFixed(2))}
                  >
                  </TextInputField>
                </Table.TextCell>
              </Table.Row>



            )
          })}
        </Pane>

      </Pane>
    </Pane>
  )
}

export default NewBalancePaymentForm;