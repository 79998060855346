import { gql } from 'apollo-boost';

const QUERY_EXPENSES_BY_DATE = gql`
  query ExpensesByDate($publisher: Int!, $startDate: String, $endDate: String) {
  	expensesbydate(publisher: $publisher, startDate: $startDate, endDate: $endDate) {
  		expenseDate,
  		amount
  	}
  }
`;

export default QUERY_EXPENSES_BY_DATE;