import { gql } from 'apollo-boost';

const QUERY_EXPENSE = gql`
  query Expense($publisher: Int!, $id: Int!) {
    expense(publisher: $publisher, id: $id) {
      id,
      product {
        id,
        title,
        format {
          id,
          value,
          description
        },
      },
      category {
        id,
        value
      },
      adminNotes,
      expenseDate,
      amount,
      locked,
      lastEditor {
        id,
        email
      },
      created,
      modified,
    }
  }
`;

export default QUERY_EXPENSE;