import { gql } from 'apollo-boost';

const CREATE_EXPENSE_MUTATION = gql`
  mutation CreateExpenseMutation(
    $publisherId: Int!,
    $expense: String!
  ) {
    createExpense(
      publisherId: $publisherId,
      expense: $expense
    ) {
      message
    }
  }
`;

export default CREATE_EXPENSE_MUTATION;