import React from "react";
import { useSelector } from 'react-redux';
import { useHistory} from "react-router-dom";



import {
  Pane,
  Paragraph,
  CogIcon,
  LogOutIcon,
  ErrorIcon,
  HomeIcon,
  Menu,
  Popover,
  Position,
  ChevronRightIcon
} from 'evergreen-ui';
import { useState } from 'react';
import CloudImage from "../CloudImage";


function UserMenu(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publishers = useSelector((state) => state.publishers.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const [imageUrl, setImageUrl] = useState("");
  const history = useHistory();


  const handleLogout = e => {
    e.preventDefault();

    fetch('/api/v1/auth/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        localStorage.clear();
        window.location.replace('/login');
      });
  };


  const isOwner = publishers.filter(item => item.role.name === "owner");

  

  return (
    <Pane>
      <Paragraph size={300} color="white" fontSize="10px" textTransform="uppercase" textAlign="left">{currentpublisher.name} </Paragraph>
      <Paragraph size={300} color="var(--lightgreen)" fontSize="10px" textTransform="uppercase" textAlign="left" marginLeft={4}>-- {publisherGlobalData.plan ? publisherGlobalData.plan : "Free Plan"} </Paragraph>

      {publishers.length > 0 &&
        <Pane marginTop={8} >

          <Popover
            content={({ close }) => (
              <Pane border="default">
                <Menu>
                  <Menu.Item icon={CogIcon} onSelect={e => {history.push( '/user'); close()}}> User Settings </Menu.Item>
                  {isOwner.length > 0 && <Menu.Item icon={HomeIcon} onSelect={e => {history.push( '/publisher'); close()}}>  Publisher Settings </Menu.Item>}
                  <Menu.Item icon={LogOutIcon} onSelect={e => handleLogout(e)}>Logout</Menu.Item>
                  <Menu.Item icon={ErrorIcon} onSelect={e => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfa-d5ia3BuTfOO51Xrd8mWaRipJS4rwONrVWvHfSdY0jkz2A/viewform', "_blank")}>  Report Bug</Menu.Item>
                </Menu>
              </Pane>)}
            position={Position.RIGHT}>
            <Pane display="flex" alignItems="center" justifyContent="space-between" className="user-menu-button">
              <Pane display="flex" alignItems="center" >
                <CloudImage avatar={true} size={30} name={publishers[0].firstName + " " + publishers[0].lastName} src={publishers[0]?.photoUrl} />
                <Pane marginLeft={8} display="flex" flexDirection="column" alignItems="flex-start">
                  <Paragraph size={300} color="white">Hi, {!!publishers[0].firstName ? publishers[0].firstName : "User"}! </Paragraph>
                </Pane>
              </Pane>
              <ChevronRightIcon marginLeft={8} size={14} color="white" />
            </Pane>
          </Popover>
        </Pane>

      }
    </Pane>
  );
}

export default UserMenu;