import { gql } from 'apollo-boost';

const CREATE_ADDRESS_MUTATION = gql`
  mutation CreateAddressMutation(
    $contactType: String!,
    $itemId: Int!,
    $data: String!
  ) {
    createAddress(
      contactType: $contactType,
      itemId: $itemId,
      data: $data
    ) {
      message
    }
  }
`;

export default CREATE_ADDRESS_MUTATION;