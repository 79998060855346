import React, { useState, useEffect } from "react";
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';
import { makeDateString, commaSepThou } from '../../../util.js';
import QUERY_GROSS_SALES_BY_DATE from "../../../graphql/queries/QueryGrossSalesByDate.js";

import {
  Pane,
  Paragraph,
  EmptyState,
  InfoSignIcon
} from 'evergreen-ui';


import {
  DateFieldInput,
} from "../../../inputs/index.js";

const GrossSalesBySalesperson = props => {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  const today = new Date();
  const prevMonth = new Date();
  const initStartDate = makeDateString(new Date(prevMonth.setDate(prevMonth.getDate() - 730)));
  const initEndDate = makeDateString(today);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [chartData, setChartData] = useState([]);

  const handleChangeDate = obj => {
    let newDate = makeDateString(obj.val);
    if (obj.key === "startDate") {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }

  }



  const { loading, error, data } = useQuery(QUERY_GROSS_SALES_BY_DATE, {
    variables: { publisher: parseInt(currentpublisher.id), startDate: startDate, endDate: endDate },
  });


  useEffect(() => {
    if (data && data.grosssalesbydate.length > 0) {
      const summedObj = {};
      data.grosssalesbydate.forEach(item => {
        let salesperson = "None";
        if (item.salesperson) {
          salesperson = item.salesperson.value;
        }
        if (summedObj.hasOwnProperty(salesperson)) {
          summedObj[salesperson] = summedObj[salesperson] + parseFloat(item.totalAmount);
        } else {
          summedObj[salesperson] = parseFloat(item.totalAmount);
        }
      });
      const totalSales = data.grosssalesbydate.map(item => parseFloat(item.totalAmount)).reduce((prev, next) => prev + next);

      const finalArr = [];
      for (let item in summedObj) {
        finalArr.push({ name: item, value: summedObj[item].toFixed(2), percentage: (summedObj[item] * 100 / totalSales).toFixed(2) });
      }
      finalArr.sort((a, b) => b.value - a.value);
      setChartData(finalArr);
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <Pane background="white" elevation={2} padding={16} border={"default"} marginRight={16}>
      <Paragraph size={500} fontWeight="bolder" marginRight={8} color="var(--green)">Sales by Salesperson</Paragraph>
      <Pane display="flex" flexDirection="row" justifyContent="flex-start" marginY={8}>
        <Pane marginRight={32}>

          <DateFieldInput el="startDate" value={startDate} editing={true} handleChange={handleChangeDate} formLabel="Start Date" width={300} />
        </Pane>
        <DateFieldInput el="endDate" value={endDate} editing={true} handleChange={handleChangeDate} formLabel="End Date" width={300} />
      </Pane>

      {data && data.grosssalesbydate.length > 0 ?
        <Pane>

          {chartData.slice(0, 10).map((item, index) => {
            return (
              <Pane key={item.name} marginBottom={4}>
                <Paragraph size={300}>{index + 1}. {currency}{commaSepThou(item.value)}: {item.name} ({item.percentage}% of total sales)</Paragraph>
              </Pane>
            );
          })}

        </Pane>

        :
        <Pane height={200} width={300}>
          <EmptyState
            background="light"
            title="No Sales Recorded For Selected Dates"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5"
          />
        </Pane>



      }



    </Pane>
  );
}

export default GrossSalesBySalesperson;
