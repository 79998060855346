import React, { useState, useEffect } from "react";
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';
import {NotSeeingItems} from "../../components/NotSeeingItems.js";

import QUERY_PRODUCTS from "../../graphql/queries/Products/QueryProducts.js";


import {
  Pane,
  Button,
  SelectMenu,
  Paragraph,
  Card,
  Spinner,
  toaster,
} from 'evergreen-ui';

function SelectProduct(props) {

  const [selected, setSelected] = useState("");
  const currentpublisher = useSelector((state) => state.currentpublisher.value);

  const [products, setProducts] = useState([]);
  const { loading, error, data, refetch } = useQuery(QUERY_PRODUCTS, {
    variables: { publisher: parseInt(currentpublisher.id) },
  });


  useEffect(() => {
    if (data) {
      setProducts(data.products.filter(prod => !prod.frozen));
    }
  }, [data, currentpublisher.id, refetch]);

  if (loading){
    return(
    <Button disabled={true}>Loading Products<Spinner marginLeft={8} size={12}/></Button> )
  }
  if (error){
    toaster.danger("Error loading products. Refresh the page or contact developer", { duration: 15 })
    return (<Button disabled={true}>No Products Found</Button> 
    )
  }

  return (
    <Pane >
      <Paragraph color="#667b7f" size={300} marginBottom={8}> Product </Paragraph>
      {props.editing ?
        <SelectMenu
          title=""
          titleView={({ close, headerHeight }) => {
            return (
              <NotSeeingItems headerHeight={headerHeight} close={close} items="products" parentItem={props.parent}/>
            )
          }}
          closeOnSelect={true}
          options={products.map((item) => ({
            key: `product${item.id}`,
            label: `${item.title} (${item.format.description})`,
            value: JSON.stringify(item),
            disabled: item.frozen,
            style: "{color:red}"
          }))}
          selected={selected}
          onSelect={(option) => {
            setSelected(option.label)
            props.handleAttachProduct(option.value);
          }}>
          <Button >{props.product?.title ? `${props.product.title} (${props.product.format.description})` : 'Select Product...'}</Button>

        </SelectMenu> :
        props.product ?
          <Card padding={8} border="default"> <Paragraph size={300}> {props.product.title} ({props.product.format.description}) </Paragraph></Card> :
          <Paragraph size={300} color="gray"> No Product Linked </Paragraph>}
    </Pane>

  )
}

export default SelectProduct;