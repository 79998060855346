import { gql } from 'apollo-boost';

const CREATE_PAYMENTTYPE_MUTATION = gql`
  mutation CreatePaymentTypeMutation(
    $publisherId: Int!,
    $paymentType: String!
  ) {
    createPaymentType(
      publisherId: $publisherId,
      paymentType: $paymentType
    ) {
      message
    }
  }
`;

export default CREATE_PAYMENTTYPE_MUTATION;