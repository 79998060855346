import { gql } from 'apollo-boost';

const DELETE_PRODUCT_CREATOR_MUTATION = gql`
  mutation DeleteProductCreatorMutation(
    $id: Int!
  ) {
    deleteProductCreator(
      id: $id
    ) {
      message
    }
  }
`;

export default DELETE_PRODUCT_CREATOR_MUTATION;