// TODO improve MobileNav in the future
import { useHistory } from "react-router-dom";

import {
  Popover,
  Menu,
  Position,
  CaretDownIcon,
  Button,
} from 'evergreen-ui';

function MobileNav(props) {

  let history = useHistory();

  const handleRoute = route => {
    history.push(route);
  };

  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          <Menu.Item onSelect={e => handleRoute("/dashboard")}>Dashboard</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/products")}>Products</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/products")}>Orders</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/products")}>Customers</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/creators")}>Creators</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/products")}>Royalties</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/products")}>Expenses</Menu.Item>
          <Menu.Divider />
          <Menu.Item onSelect={e => handleRoute("/products")}>Reporting</Menu.Item>
          <Menu.Divider />
        </Menu>
      }
    >
      <Button borderWidth={0} className="user-menu-button" margin={16} iconBefore={CaretDownIcon}>
        Go To...
      </Button>
    </Popover>
  );
}

export default MobileNav;