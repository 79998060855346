import { gql } from 'apollo-boost';

const UPDATE_EMAIL_MUTATION = gql`
  mutation UpdateEmailMutation(
    $itemId: Int!,
    $contactType: String!,
    $changed: String!
  ) {
    updateEmail(
      itemId: $itemId,
      contactType: $contactType,
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_EMAIL_MUTATION;