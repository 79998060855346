import { gql } from 'apollo-boost';

const DELETE_CREATOR_MUTATION = gql`
  mutation DeleteCreatorMutation(
    $id: Int!,
  ) {
    deleteCreator(
      id: $id,
    ) {
      message
    }
  }
`;

export default DELETE_CREATOR_MUTATION;