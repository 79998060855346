import React, { useState} from "react";
import { useMutation } from 'react-apollo';
import { useSelector, useDispatch } from 'react-redux';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';
import { makeInputs } from '../../util.js';
import CreatorBox from "./CreatorBox.js";
import ImageUpload from "../../components/ImageUpload.js";
import CloudImage from "../../components/CloudImage.js";
import CREATE_IMPRINT_MUTATION from "../../graphql/mutations/PublisherData/CreateImprint.js";
import CREATE_SERIES_MUTATION from "../../graphql/mutations/PublisherData/CreateSeries.js";

import {
  TextInputFieldInput,
  TextInputWithUnitInput,
  TextareaFieldInput,
  ArrayInputFieldInput,
  DateFieldInput,
  CurrencyInput,
  ForeignKeyDropDownInput
} from "../../inputs/index.js";

import {
  Pane,
  Paragraph,
  Tablist,
  Tab,
} from 'evergreen-ui';

function ProductForm(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const onixGlobalData = useSelector((state) => state.onixGlobalData.value);
  const dispatch = useDispatch();
  const [tabs] = useState(['PRODUCT DETAILS', 'MARKETING/IMAGES'])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [createImprint, { imprintdata }] = useMutation(CREATE_IMPRINT_MUTATION);
  const [createSeries, { seriesdata }] = useMutation(CREATE_SERIES_MUTATION);

  const handleAddNewImprint = async (val) => {
    const imprints = [].concat(publisherGlobalData.publisherimprints);

    const results = await createImprint(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          imprint: val,
        }
      });


    const newImprint = { id: results.data.createImprint.message, value: val }
    imprints.push(newImprint);
    // console.log(newImprint);
    dispatch(setpublisherGlobalData({ publisherimprints: imprints }));
    props.handleUpdateStaged({ key: "imprint", val: newImprint });
  }

  const handleAddNewSeries = async (val) => {
    const series = [].concat(publisherGlobalData.productseriess);
    const results = await createSeries(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          series: val,
        }
      });

    const newSeries = { id: results.data.createSeries.message, value: val }
    series.push(newSeries);
    dispatch(setpublisherGlobalData({ productseriess: series }));
    props.handleUpdateStaged({ key: "series", val: newSeries });
  }

  const inputs = {
    status: { width: 120, comp: ForeignKeyDropDownInput, formLabel: "STATUS:", handleChange: props.handleUpdateStaged, group: 10, values: onixGlobalData.onixproductavailabilitys, opts: { badge: true, marginRight: 16, short:true } },
    title: { width: 350, comp: TextInputFieldInput, formLabel: "Title", handleChange: props.handleUpdateStaged, group: 1, opts: { mandatory: true, marginRight: 16 } },
    subtitle: { width: 350, comp: TextInputFieldInput, formLabel: "Subtitle", handleChange: props.handleUpdateStaged, group: 1, opts: { marginRight: 16 } },
    series: { width: 150, comp: ForeignKeyDropDownInput, formLabel: "Series", handleChange: props.handleUpdateStaged, handleAddNew: handleAddNewSeries, group: 11, values: publisherGlobalData.productseriess, opts: { marginRight: 16, marginBottom: 16, new:true } },
    volume: { width: 75, comp: TextInputFieldInput, formLabel: "Volume", handleChange: props.handleUpdateStaged, group: 11, opts: { marginRight: 16, marginBottom: 16 } },
    edition: { width: 75, comp: TextInputFieldInput, formLabel: "Edition", handleChange: props.handleUpdateStaged, group: 11, opts: { marginRight: 16, marginBottom: 16 } },

    imprint: { width: 350, comp: ForeignKeyDropDownInput, formLabel: "Imprint", handleChange: props.handleUpdateStaged, handleAddNew: handleAddNewImprint, group: 12, values: publisherGlobalData.publisherimprints, opts: { marginRight: 16, new:true } },
    description: { width: 350, comp: TextareaFieldInput, formLabel: "Description", handleChange: props.handleUpdateStaged, group: 13, opts: { marginRight: 16 } },

    isbn: { width: 200, comp: TextInputFieldInput, formLabel: "ISBN/UID", handleChange: props.handleUpdateStaged, group: 2, opts: { marginRight: 16 } },

    retailPrice: { width: 75, comp: CurrencyInput, formLabel: "Retail Price", handleChange: props.handleUpdateStaged, group: 3, opts: { mandatory: true, marginRight: 16 } },
    inventoryQuantity: { width: 75, comp: TextInputFieldInput, formLabel: "Inventory", handleChange: props.handleUpdateStaged, group: 3, opts: { marginRight: 16, marginBottom: 16 } },
    cartonQty: { width: 75, comp: TextInputFieldInput, formLabel: "Carton Qty", handleChange: props.handleUpdateStaged, group: 3, opts: { marginRight: 16 } },

    formatDetail: { width: 120, comp: ForeignKeyDropDownInput, formLabel: "Format Detail", handleChange: props.handleUpdateStaged, group: 41, values: onixGlobalData.onixproductformdetails, opts: { marginRight: 16, short:true } },
    format: { width: 120, comp: ForeignKeyDropDownInput, formLabel: "Format", handleChange: props.handleUpdateStaged, group: 4, values: onixGlobalData.onixproductforms, opts: { mandatory: true, marginRight: 16, short:true} },

    shipDate: { width: 75, comp: DateFieldInput, formLabel: "Ship Date", handleChange: props.handleUpdateStaged, group: 5, opts: { marginRight: 16, tooltip: "The date this title is released from the warehouse" } },
    publicationDate: { width: 75, comp: DateFieldInput, formLabel: "Publication Date", handleChange: props.handleUpdateStaged, group: 5, opts: { marginRight: 16, tooltip: "The date this title is on sale in stores" } },

    weight: { width: 75, comp: TextInputWithUnitInput, formLabel: "Weight", handleChange: props.handleUpdateStaged, group: 6, opts: { marginRight: 16 } },
    width: { width: 75, comp: TextInputWithUnitInput, formLabel: "Width", handleChange: props.handleUpdateStaged, group: 6, opts: { marginRight: 16 } },
    height: { width: 75, comp: TextInputWithUnitInput, formLabel: "Height", handleChange: props.handleUpdateStaged, group: 6, opts: { marginRight: 16 } },
    thickness: { width: 75, comp: TextInputWithUnitInput, formLabel: "Thickness", handleChange: props.handleUpdateStaged, group: 61, opts: { marginRight: 16 } },
    pageCount: { width: 100, comp: TextInputFieldInput, formLabel: "Page Count", handleChange: props.handleUpdateStaged, group: 61, opts: { marginRight: 16, marginBottom: 24 } },
    adminNotes: { width: 400, comp: TextareaFieldInput, formLabel: "Admin Notes", handleChange: props.handleUpdateStaged, group: 7, opts: { marginRight: 16 } },

    audience: { width: 120, comp: ForeignKeyDropDownInput, formLabel: "Audience", handleChange: props.handleUpdateStaged, group: 81, values: onixGlobalData.onixaudiencetypes, opts: { marginRight: 16 } },
    audienceDetails: { width: 200, comp: TextInputFieldInput, formLabel: "Audience Details", handleChange: props.handleUpdateStaged, group: 81, opts: { marginRight: 16 } },

    keySellingPoints: { width: 400, comp: TextareaFieldInput, formLabel: "Key Selling Points", handleChange: props.handleUpdateStaged, group: 8, opts: { marginRight: 16 } },
    marketing: { width: 400, comp: TextareaFieldInput, formLabel: "Marketing Notes", handleChange: props.handleUpdateStaged, group: 8, opts: { marginRight: 16 } },

    bisac: { width: 75, comp: ArrayInputFieldInput, formLabel: "BISAC", handleChange: props.handleUpdateStaged, group: 82, max: 4 },

  }

  // loop over the staged array to make the columns
  const formInputs = makeInputs(props.staged, inputs, props.editing);

  return (
    <Pane >
      <Tablist>
        {tabs.map((tab, index) => (
          <Tab
            aria-controls={`panel-${tab}`}
            isSelected={index === selectedIndex}
            key={tab}
            onSelect={() => setSelectedIndex(index)}
            className="custom-tab"
            fontWeight="bold"
          >
            {tab}
          </Tab>
        ))}
      </Tablist>
      <Pane  >
        <Pane id="item-details" elevation={1}
          aria-labelledby={'PRODUCT DETAILS'}
          aria-hidden={0 !== selectedIndex}
          display={0 === selectedIndex ? 'flex' : 'none'}
          key={'PRODUCT DETAILS'}
          role="tabpanel"
          className="tab-content"
          maxWidth="calc(100vw - 232px)"
        >
          <Pane display="flex" flexDirection="column" borderRight="default" justifyContent="flex-start" alignItems="flex-start">
            {formInputs.group10}
            {formInputs.group1}
            <Pane display="flex">
              {formInputs.group11}
            </Pane>
            {formInputs.group12}
            {formInputs.group13}
          </Pane>
          <Pane display="flex" flexDirection="column" paddingLeft={12} borderRight="default">
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
              {formInputs.group4}
              {props.staged.format && props.staged.format.description === "Hardcover" && formInputs.group41}
            </Pane>
            {formInputs.group2}
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
              {formInputs.group3}
            </Pane>
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
              {formInputs.group5}
            </Pane>
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
              {formInputs.group6}
            </Pane>
            <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
              {formInputs.group61}
            </Pane>
          </Pane>
          <Pane paddingLeft={12} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            {props.editMode !== "new" && (
              <CreatorBox
                editing={props.editing}
                onixGlobalData={onixGlobalData}
                royaltySet={props.royaltySet}
                productcreatorSet={props.productcreatorSet}
                handleUpdateCreatorStaged={props.handleUpdateCreatorStaged}
                handleDelete={props.handleDeleteProductCreator}
                creators={publisherGlobalData.publishercreators}
                productId={props.staged.id}
                editMode={props.editMode}
              />
            )}
            <Pane marginTop={16}>
              {formInputs.group7}
            </Pane>
          </Pane>
        </Pane>
        <Pane id="marketing" elevation={1}
          aria-labelledby={'MARKETING/IMAGES'}
          aria-hidden={1 !== selectedIndex}
          display={1 === selectedIndex ? 'flex' : 'none'}
          key={'MARKETING/IMAGES'}
          role="tabpanel"
          className="tab-content"
        >
          <Pane paddingRight={16} borderRight="default" flex={2}>
            <Pane display="flex" flexDirection="column">

              {props.editMode !== "new" &&
                <Pane>
                  <Paragraph size={300} color="#667b7f" fontWeight="bold">COVER IMAGE</Paragraph>
                  {props.editing ?
                    <Pane display="flex">
                      <Pane>
                        <Paragraph>{props.getImageFilename(props.staged.coverImage)}</Paragraph>
                        <CloudImage path={props.staged.coverImage} customClass="" />
                      </Pane>
                      <ImageUpload handleImageUpload={props.setCoverImage} pathType="products" itemId={props.staged.id} />
                    </Pane>
                    : <CloudImage path={props.staged.coverImage} customClass="" />
                  } </Pane>}

            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="column" paddingX={16} borderRight="default" flex={3}>
            <Pane display="flex" justifyContent="flex-start" alignItems="flex-start">
              {formInputs.group81}
            </Pane>
            {formInputs.group82}
            {formInputs.group8}

          </Pane>
          <Pane paddingLeft={16} flex={3} >
            {props.editMode !== "new" && (
              <Paragraph size={300} color="#667b7f">Interior Images</Paragraph>
            )}
            {props.editMode !== "new" && props.editing && (
              <Pane>
                {props.staged.interiorImages && props.checkInteriorImages(props.staged.interiorImages) && JSON.parse(props.staged.interiorImages).map(img => {
                  return (
                    <Paragraph key={`int${img}${props.staged.id}`}>{props.getImageFilename(img)}</Paragraph>
                  )
                })}
                <ImageUpload handleImageUpload={props.setInteriorImage} pathType="products" itemId={props.staged.id} />
              </Pane>
            )}
            {props.editMode !== "new" && !props.editing && (
              <Pane display="flex" flexWrap="wrap" maxHeight={600} overflow="auto" border="default" padding={8}>
                {props.staged.interiorImages && props.checkInteriorImages(props.staged.interiorImages) ? JSON.parse(props.staged.interiorImages).map(img => {
                  return (

                    <Pane key={`${img}${props.staged.id}`} margin={8}>
                      <CloudImage path={img} customClass="" />
                    </Pane>
                  )
                }) : (
                  <Paragraph>(No images)</Paragraph>
                )}
              </Pane>
            )}
          </Pane>

        </Pane>

      </Pane></Pane>
  )
}

export default ProductForm;
