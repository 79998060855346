import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';
import QUERY_CREATORS from "../../graphql/queries/Creators/QueryCreators.js";
import { NotSeeingItems } from "../../components/NotSeeingItems.js";

import {
  Button,
  SelectMenu,
  Spinner
} from 'evergreen-ui';

function SelectCreator(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);

  const [creatorSelected, setCreatorSelected] = useState("");
  const [creators, setCreators] = useState([]);


  const { loading, error, data, refetch: _refetch} = useQuery(QUERY_CREATORS, {
    variables: { publisher: parseInt(currentpublisher.id) },
  });

  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);


  useEffect(() => {
    if (data) {
      // TODO: Create different queries for each
      if (props.parent === "royalties") {
        const filteredCreators = data.creators.filter(creator => {
          let existingRoyalties = creator.royaltySet.map(royalty => royalty.product.id)
          let productSet = creator.productcreatorSet.filter(item => !existingRoyalties.includes(item.product.id) && !item.product.frozen)
          return productSet.length > 0
        })
        setCreators(filteredCreators)
      }
      else {
        setCreators(data.creators.filter(item => item.royaltySet.filter(royalty => !royalty.product.frozen).length > 0))
      }
    }
  }, [data, props.parent]);

  useEffect(() => {
    refetch()
  },[props.royalties, refetch])

  if (loading) return (
        <Button disabled={true}>Loading Creators<Spinner marginLeft={8} size={12}/></Button> 
);
  if (error) return `Error! ${error}`;

  return (
    <SelectMenu
      title=""
      titleView={({ close, headerHeight }) => {
        return (
          <NotSeeingItems headerHeight={headerHeight} close={close} items="creators" parentItem={props.parent}/>
        )
      }}
      options={creators.map((item) => ({
        key: "creator" + item.id,
        label: `${item.displayName} ${item.paymentName ? "(" + item.paymentName + ")" : ""}`,
        value: JSON.stringify(item),
        button: item.displayName
      }))}
      selected={creatorSelected}
      closeOnSelect={true}
      onSelect={(option) => {
        setCreatorSelected(option.button)
        props.handleNewCreator(option.value)
      }}>
      <Button>{creatorSelected ? creatorSelected : 'Select Creator...'}</Button></SelectMenu>
  )
}

export default SelectCreator;