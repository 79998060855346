export const baseOrderObject = () => {
  return {
    id: "",
    customer: {
      id: "",
      firstName: "",
      lastName: "",
      company: "",
      customeremailSet: [
        {
          id: "",
          value: "",
          primary: ""
        }
      ],
      customerphonenumberSet: [
        {
          id: "",
          value: "",
          primary: ""
        }
      ],
      customeraddressSet: [
        {
          id: "",
          line1: "",
          line2: "",
          city: "",
          stateOrRegion: "",
          country: "",
          zipcode: "",
          primary: "",
          addressType: ""
        }
      ]
    },
    orderAddress: {
      id: "",
      line1: "",
      line2: "",
      city: "",
      stateOrRegion: "",
      country: "",
      zipcode: "",
      primary: "",
      addressType: "",
    },
    orderEmail: {
      id: "",
      value: "",
      primaryid: ""
    },
    orderPhone: {
      id: "",
      value: "",
      primary: ""
    },
    orderStatus: {
      id: "",
      value: ""
    },
    poNumber: "",
    salesperson: {
      id: "",
      value: ""
    },
    paymentTerm: {
      id: "",
      value: ""
    },
    
    shippingType: {
      id: "",
      value: ""
    },
    shippingCost: "",
    adminNotes: "",
    paid: false,
    paidDate: "",
    shipped: false,
    shipDate: "",
    orderproductSet: [
      {
        id: "",
        product: {
          id: "",
          title: "",
          isbn: "",
          retailPrice: "",
          created: "",
          format: {
            id: "",
            value: "",
            description: ""
          }
        },
        quantity: "",
        discountPerUnit: "",
        netPrice: "",
        retailPrice: "",
        wholesale: ""
      },
    ],
    orderpaymentSet: [
      {
        id: "",
        method: {
          id: "",
          value: ""
        },
        amount: "",
        memo: ""
      }
    ],
    locked: false,
    lastEditor: {
      id: "",
      email: ""
    },
    created: "",
    modified: ""
  }
}