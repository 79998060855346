import { gql } from 'apollo-boost';

const DELETE_ORDER_MUTATION = gql`
  mutation DeleteOrderMutation(
    $id: Int!,
  ) {
    deleteOrder(
      id: $id,
    ) {
      message
    }
  }
`;

export default DELETE_ORDER_MUTATION;