import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useMutation } from 'react-apollo';
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, } from 'react-redux';

// ------- FUNCTIONS
import { commaSepThou, sortItems } from '../../util.js';
import { baseCreatorTemplate } from './baseCreatorTemplate.js';

// ------- QUERIES/MUTATIONS
import QUERY_CREATOR from "../../graphql/queries/Creators/QueryCreator.js";
import UPDATE_CREATOR_MUTATION from "../../graphql/mutations/Creators/UpdateCreator.js";
import DELETE_CREATOR_MUTATION from "../../graphql/mutations/Creators/DeleteCreator.js";

// ------- COMPONENTS
import ImageUpload from "../../components/ImageUpload.js";
import CloudImage from "../../components/CloudImage.js";
import CreatorForm from "./CreatorForm.js";
import StatementTable from "./StatementTable.js";
import Breadcrumbs from "../../components/layout/Breadcrumbs.js";
import SaveButtons from "../../components/layout/SaveButtons.js";
import LeavePagePrompt from "../../components/LeavePagePrompt.js";
import { ContactInfo } from "../../inputs/index.js";

import {
  Pane,
  Paragraph,
  Heading,
  Badge,
  toaster,
  Tablist,
  Tab,
  EmptyState,
  InfoSignIcon,
  Card
} from 'evergreen-ui';

function Creator() {
  const userid = useSelector((state) => state.user.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const { creatorId } = useParams();
  const [tabs] = useState(['CREATOR DETAILS', 'PAST STATEMENTS'])
  const [selectedIndex, setSelectedIndex] = useState(0)

  // States
  const [creator, setCreator] = useState(baseCreatorTemplate);
  const [changed, setChanged] = useState([]);
  const [staged, setStaged] = useState(baseCreatorTemplate);
  const [editing, setEditing] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [saved, setSaved] = useState(false);
  const [balance, setBalance] = useState([]);
  const [canDelete, setCanDelete] = useState(false);

  const history = useHistory();

  // TODO check what these states do

  // Mutations
  const [updateCreator, { creatordata }] = useMutation(UPDATE_CREATOR_MUTATION);
  const [deleteCreator, { deletedata }] = useMutation(DELETE_CREATOR_MUTATION);


  // Functions
  const handleUpdateStaged = obj => {
    // check for an existing record in changed
    if (saved) {setSaved(false)}
    delete obj.val.__typename;
    delete obj.val.label;
    let newArr = changed.filter(item => item.hasOwnProperty("key") && item.key !== obj.key);
    newArr.push(obj)
    setChanged(newArr);
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }));
  }

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_CREATOR, {
    variables: { publisher: parseInt(currentpublisher.id), id: parseInt(creatorId) },
  });
  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);

  const handleRefetch = () => {
    refetch()
  }
  const resetForm = () => {
    setStaged(creator);
    setChanged([]);
    setSaved(false)
  }

  const handleUpdateCreator = async () => {
    try {
      let updateResult = await updateCreator(
        {
          variables: {
            id: parseInt(staged.id),
            changed: JSON.stringify(changed),
          }
        });
      toaster.closeAll()
      toaster.success(updateResult.data.updateCreator.message, { duration: 3 })
      setSaved(true)
      setChanged([])
      return "Saved"

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving creator. Try again or contact developer", { duration: 5 })
      return error.message;
    }
  }

  const handleDeleteCreator = async () => {
    try {
      const results = await deleteCreator(
        {
          variables: {
            id: parseInt(staged.id),
          }
        });
      toaster.closeAll()
      toaster.success(`Creator ${results.data.deleteCreator.message} deleted succesfully!`, { duration: 10 })
      history.push('/creators')

    }
    catch (error) {
      toaster.closeAll()
      toaster.danger("Error deleting creator. Try again or contact developer", { duration: 5 })
      return error.message;
    }
  }



  const setCreatorImage = async (url) => {
    try {
      let imageResult = await updateCreator(
        {
          variables: {
            id: parseInt(staged.id),
            changed: JSON.stringify([{ key: "photoUrl", val: url }]),
          }
        });
      toaster.success(imageResult.data.updateCreator.message, { duration: 3 })

    } catch (error) {
      toaster.closeAll()
      toaster.danger("Error saving creator. Try again or contact developer", { duration: 5 })
      return error.message;
    }
    refetch();
  }

  const fieldDict = {
    "Address": { setName: "creatoraddressSet" },
    "Email": { setName: "creatoremailSet" },
    "Phone": { setName: "creatorphonenumberSet" }
  }

  const currency = publisherGlobalData.currency
  ? publisherGlobalData.currency.symbol
  : "$";

  

  useEffect(() => {
    if (data && data.creator.length) {
      let creatordata = data.creator[0]
      if (creatordata.royaltySet) {
        let balance = 0;
        creatordata.royaltySet.forEach(item => balance = balance + parseFloat(item.owed) - parseFloat(item.paid))
        setBalance(balance.toFixed(2))
      }
      if (creatordata.creatorphonenumberSet) {
        let clone = [].concat(creatordata.creatorphonenumberSet);
        clone.sort((a, b) => {
          return b.primary - a.primary;
        });
        creatordata.creatorphonenumberSet = clone;
      }
      if (creatordata.creatoremailSet) {
        let clone = [].concat(creatordata.creatoremailSet);
        clone.sort((a, b) => {
          return b.primary - a.primary;
        });
        creatordata.creatoremailSet = clone;
      }
      if (creatordata.creatoraddressSet) {
        let clone = [].concat(creatordata.creatoraddressSet);
        clone.sort((a, b) => {
          return b.primary - a.primary;
        });
        creatordata.creatoraddressSet = clone;
      }
      const linkedModelCount = creatordata.royaltySet.length + creatordata.productcreatorSet.length
      if (linkedModelCount === 0) {
        setCanDelete(true)
      }
      setCreator(Object.assign({}, creatordata));
      setStaged(Object.assign({}, creatordata));
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  if (data.creator.length === 0) return (
    <Pane>
      <Pane paddingX={16} paddingTop={16}>
        <Breadcrumbs pageLabel="CREATOR" sourceUrl="/creators" />
      </Pane>
      <Pane display="flex" justifyContent="center" marginTop={60}>
        <Card alignItems="center" width="50%" elevation={2}>
          <EmptyState
            background="light"
            title="Creator not found"
            orientation="vertical"
            icon={<InfoSignIcon size={12} color="#C1C4D6" />}
            iconBgColor="#EDEFF5" />
        </Card>

      </Pane>

    </Pane>);

  return (
    <Pane>
      {
        editing &&
        <LeavePagePrompt
          userid={userid}
          itemid={creatorId}
          itemType="creator"
          when={!!(changed?.length)}
        />
      }
      <Pane >
        <Pane borderBottom="solid" borderBottomColor="#e6e4e0" borderBottomWidth="1px" paddingX={16} paddingTop={16}>
          <Breadcrumbs pageLabel="CREATOR" curPage={creator.id} sourceUrl="/creators" />
          <Pane marginTop={8} marginBottom={8} size={300} display="flex" justifyContent="space-between" >
            <Pane display="flex" alignItems="flex-end">
              <Paragraph marginLeft={4} size={300} fontSize={18} fontWeight="bold"> {creator.paymentName} </Paragraph>

              <Paragraph marginLeft={8} size={300}><Badge color="neutral">ID: {creator.id}</Badge></Paragraph>
              <Paragraph marginLeft={8} size={300}><Badge color={balance < 0 ? "green" : balance >= 100 ? "red" : "neutral"}>Balance: {balance < 0 ? "-" : ""}{currency}{commaSepThou(Math.abs(balance).toFixed(2))}</Badge></Paragraph>
              <Paragraph size={300} marginLeft={8}>
                <Link to={{
                  pathname: '/products',
                  state: { query: {productcreator__creator__id: creator.id}, queryTitle: "Products Associated with " + creator.displayName, sourceURL: '/creators/' + creator.id, sourcePageTitle: "CREATOR " + creator.id }
                }}>

                  PRODUCTS ({creator.productcreatorSet ? creator.productcreatorSet.length : "None"})
                </Link>
              </Paragraph>
              <Paragraph size={300} marginLeft={8}>
                <Link to={{
                  pathname: '/royalties',
                  state: { query: {creator__id: creator.id}, queryTitle: "Royalties Associated with " + creator.displayName, sourceURL: '/creators/' + creator.id, sourcePageTitle: "CREATOR " + creator.id }
                }}>

                  ROYALTIES ({creator.royaltySet ? creator.royaltySet.length : "None"})
                </Link>
              </Paragraph>

            </Pane>
            <Pane>
              <SaveButtons
                itemType="creator"
                editing={editing}
                locked={creator.locked}
                itemId={staged.id}
                setEditing={setEditing}
                setCancelled={setCancelled}
                handleUpdate={handleUpdateCreator}
                handleDelete={handleDeleteCreator}
                resetForm={resetForm}
                refetch={handleRefetch}
                canDelete={canDelete}
                deleteMsg={"Cannot delete creator if there are any linked products, royalties, or payments."}
              />
            </Pane>
          </Pane>
        </Pane>


        <Pane padding={16} width="100%">
          <Tablist>
            {tabs.map((tab, index) => (
              <Tab
                aria-controls={`panel-${tab}`}
                isSelected={index === selectedIndex}
                key={tab}
                onSelect={() => setSelectedIndex(index)}
                className="custom-tab"
                fontWeight="bold"
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
          <Pane>
            <Pane id="item-details" elevation={1}
              aria-labelledby={'CREATOR DETAILS'}
              aria-hidden={0 !== selectedIndex}
              display={0 === selectedIndex ? 'flex' : 'none'}
              key={'CREATOR DETAILS'}
              role="tabpanel"
              className="tab-content"
              maxWidth="calc(100vw - 232px)"
            >
              <Pane padding={16} overflow="auto">
                <Pane display="flex">
                  <Pane marginRight={32}>
                    <Heading size={400} fontWeight="bold" color="#5e5e5e" marginBottom={8}>Creator Photo</Heading>
                    {editing ? (
                      <ImageUpload handleImageUpload={setCreatorImage} pathType="creators" itemId={creator.id} />
                    ) : (
                      <CloudImage path={creator.photoUrl ? creator.photoUrl : false} customClass=" medium" />
                    )}
                    <CreatorForm staged={staged} handleUpdateStaged={handleUpdateStaged} editing={editing} />
                  </Pane>

                  <Pane>
                  </Pane>

                  <Pane >
                    <ContactInfo
                      contactType="creator"
                      staged={staged}
                      setStaged={setStaged}
                      fieldDict={fieldDict}
                      saved={saved}
                      setSaved={setSaved}
                      phoneSet={staged.creatorphonenumberSet}
                      emailSet={staged.creatoremailSet}
                      addressSet={staged.creatoraddressSet}
                      editing={editing}
                      cancelled={cancelled}
                      refetch={refetch}
                    />
                  </Pane>
                </Pane>

              </Pane>
            </Pane>

            <Pane id="statement-details" elevation={1}
              aria-labelledby={'PAST STATEMENTS'}
              aria-hidden={1 !== selectedIndex}
              display={1 === selectedIndex ? 'flex' : 'none'}
              key={'PAST STATEMENTS'}
              role="tabpanel"
              className="tab-content"
              maxWidth="calc(100vw - 232px)"
            >
              <Pane width="100%" >
                <StatementTable
                  mode={"creator"}
                  royaltystatementSet={staged.royaltystatementSet}
                />
              </Pane>
            </Pane>
          </Pane>
        </Pane>

      </Pane>
    </Pane>
  )
}

export default Creator;
