import { gql } from 'apollo-boost';

const CREATE_EMAIL_MUTATION = gql`
  mutation CreateEmailMutation(
    $itemId: Int!,
    $contactType: String!,
    $data: String!
  ) {
    createEmail(
      itemId: $itemId,
      contactType: $contactType,
      data: $data
    ) {
      message
    }
  }
`;

export default CREATE_EMAIL_MUTATION;