import { gql } from 'apollo-boost';

const CREATE_SERIES_MUTATION = gql`
  mutation CreateSeriesMutation(
    $publisherId: Int!,
    $series: String!
  ) {
    createSeries(
      publisherId: $publisherId,
      series: $series
    ) {
      message
    }
  }
`;

export default CREATE_SERIES_MUTATION;