import { gql } from 'apollo-boost';

const QUERY_USER = gql`
	query User($id: Int) {
	  user(id: $id) {
	    id
	    firstName
	    lastName
	    email
	    publisheruserSet {
	    	id
	    	photoUrl
	    	role {
				id
	    		name
	    	}
			firstName
			lastName
	    }
	  }
	}
`;

export default QUERY_USER;