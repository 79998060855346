// TODO - JSDOC format, look into subfields
import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useQuery } from 'react-apollo';
import { CSVLink } from "react-csv";
import { sanitizeRecord, makeAdjustedString } from "../../util";
import JSON5 from 'json5';
import QUERY_PRODUCTS from "../../graphql/queries/Products/QueryProducts";
import QUERY_ORDERS from "../../graphql/queries/Orders/QueryOrders";
import QUERY_CREATORS from "../../graphql/queries/Creators/QueryCreators";
import QUERY_CUSTOMERS from "../../graphql/queries/Customers/QueryCustomers";
import QUERY_EXPENSES from "../../graphql/queries/Expenses/QueryExpenses";
import QUERY_ROYALTYS from "../../graphql/queries/Royaltys/QueryRoyaltys";
import QUERY_ROYALTY_PAYMENTS from "../../graphql/queries/Royaltys/QueryRoyaltyPayments";

import {
  Paragraph
} from 'evergreen-ui';


function CsvDownload(props) {

  const currentpublisher = useSelector((state) => state.currentpublisher.value);

  const [fetched, setFetched] = useState([]);

  var d = new Date(Date.now());
  const filename = props.csvType
    + "-"
    + d.getFullYear()
    + "-"
    + (d.getMonth() + 1)
    + "-"
    + d.getDate()
    + "-"
    + d.getHours()
    + d.getMinutes()
    + d.getSeconds()
    + ".csv";

  const extractSetItemValues = (setItem, fields, subfields) => {
    let val = [setItem].map(item => {
      let arr = [];
      for (let k in item) {
        if (!item.hasOwnProperty(k)) continue;
        if (fields.indexOf(k) > -1) {
          if (subfields.hasOwnProperty(k)) {
            let subfield = subfields[k]
            
            // if (Array.isArray(subfield)){
            //   subfield.forEach(sub => 
            //   arr.push }
            // }
            // arr.push(item[k][subfield])
          }
          else {
            arr.push(item[k]);
          }
        }
      }
      return arr.join(" -- ");
    });
    if (val.length > 0) {
      return val[0];
    } else {
      return val;
    }
  }



  const allMode = props.allmode;
  const dataType = props.csvType;

  const clean = (arr) => {
    const cleanArray = arr.map(item => {
      let newItem = {};
      for (let k in item) {
        if (!item.hasOwnProperty(k)) continue;
        if (item[k]) {
          if (props.include.indexOf(k) > -1) {
            if (k in props.config.sets) {
              let arr = [];
              if (Array.isArray(item[k])) {
                arr = item[k].map(setItem => {
                  if (setItem) {
                    let adjusted = makeAdjustedString(setItem);
                    let parsedSet = JSON5.parse(adjusted);
                    return extractSetItemValues(parsedSet, props.config.sets[k].fields, props.config.sets[k].subfields);
                  } else {
                    return ""
                  }
                });
              } else {
                let adjusted = makeAdjustedString(item[k]);
                let parsedSet = JSON5.parse(adjusted);
                arr.push(extractSetItemValues(parsedSet, props.config.sets[k].fields));
              }
              newItem[props.config.sets[k].label] = arr.join("; ");
            }
            else if (k in props.config.primarySets && item[k].length) {
              let primaryArray = []
              item[k].forEach(setItem => {
                let adjusted = makeAdjustedString(setItem);
                let parsedSet = JSON5.parse(adjusted);
                if (parsedSet.hasOwnProperty("primary") && parsedSet["primary"] === true) {
                  primaryArray.push(parsedSet)
                }
              });

              let setInfo = props.config.primarySets[k];
              let category = setInfo.label

              let primarySet = primaryArray.length ? primaryArray[0] : JSON5.parse(makeAdjustedString(item[k][0]))
              setInfo.fields.forEach(field => {
                if (primarySet.hasOwnProperty(field)) {

                  newItem[category + "_" + field] = primarySet[field]
                }

              })
            }
            else if (k in props.config.arrays) {
             let inputString = item[k]
              if (inputString.startsWith('"') && inputString.endsWith('"')) {
                // Remove outer quotes
                inputString = inputString.slice(1, -1);
              }
              
              // Remove any backslashes
              inputString = inputString.replace(/\\/g, '');
              let itemArray = JSON.parse(inputString).map(subItem => subItem.trim()).join(";")
              let category = k.replace(/([A-Z])/g, "_$1").toLowerCase()

              newItem[category] = itemArray
            }
            else if (k in props.config) {
              for (let i = 0; i < props.config[k].length; i++) {
                let subItem = props.config[k][i];
                let adjusted = makeAdjustedString(item[k]);
                let category = k.replace(/([A-Z])/g, "_$1").toLowerCase()
                newItem[category + "_" + subItem] = JSON5.parse(adjusted)[subItem];

              }
            } else {
              let category = k.replace(/([A-Z])/g, "_$1").toLowerCase()
              newItem[category] = item[k];
            }
          }
        }
      }
      return newItem;
    });
    return cleanArray;
  }

  const queryTypes = [
    {
      name: 'Products',
      query: QUERY_PRODUCTS,
      headers: [
        { label: 'id', key: 'id' },
        { label: 'isbn', key: 'isbn' },
        { label: 'title', key: 'title' },
        { label: 'subtitle', key: 'subtitle' },
        { label: 'format', key: 'format_description' },
        { label: 'creators', key: 'creators' },
        { label: 'description', key: 'description' },
        { label: 'publication_date', key: 'publication_date' },
        { label: 'status', key: 'status_description' },
        { label: 'retail_price', key: 'retail_price' },
        { label: 'weight', key: 'weight_value' },
        { label: 'width', key: 'width_value' },
        { label: 'height', key: 'height_value' },
        { label: 'thickness', key: 'thickness_value' },
        { label: 'page_count', key: 'page_count' },
        { label: 'edition', key: 'edition' },
        { label: 'volume', key: 'volume' },
        { label: 'series', key: 'series' },
        { label: 'imprint', key: 'imprint_value' },
        { label: 'publisher', key: 'publisher_name' },
        { label: 'ship_date', key: 'ship_date' },
        { label: 'carton_qty', key: 'carton_qty' },
        { label: 'inventory_qty', key: 'inventory_quantity' },
        { label: 'bisac', key: 'bisac' },
        { label: 'audience', key: 'audience_description' },
        { label: 'audience_details', key: 'audience_details' },
        { label: 'admin_notes', key: 'admin_notes' },
      ]
    },
    {
      name: 'Orders',
      query: QUERY_ORDERS,
      headers: [
        { label: 'id', key: 'id' },
        { label: 'customer', key: 'company' },
        { label: 'address_line1', key: 'order_address_line1' },
        { label: 'address_line2', key: 'order_address_line2' },
        { label: 'address_city', key: 'order_address_stateOrRegion' },
        { label: 'address_state', key: 'order_address_city' },
        { label: 'address_zipcode', key: 'order_address_zipcode' },
        { label: 'email', key:'order_email_value' },
        { label: 'phone', key:'order_phone_value' },
        { label: 'po_number', key:'po_number' },
        { label: 'salesperson', key:'salesperson_value' },
        { label: 'payment_term', key:'payment_term_value' },
        { label: 'shipping_type', key:'shipping_type_value' },
        { label: 'shipping_cost', key:'shipping_cost' },
        { label: 'total_amount', key:'total_amount' },

        { label: 'paid', key:'paid' },
        { label: 'paid_date', key:'paid_date' },
        { label: 'shipped', key:'shipped' },
        { label: 'ship_date', key:'ship_date' },
        { label: 'admin_notes', key:'admin_notes' },
      ]
    },
    {
      name: 'Customers',
      query: QUERY_CUSTOMERS,
      headers: [
        { label: 'id', key: 'id' },
        { label: 'first_name', key: 'first_name' },
        { label: 'last_name', key: 'last_name' },
        { label: 'company', key: 'company' },
        { label: 'address_line1', key: 'addresses_line1' },
        { label: 'address_line2', key: 'addresses_line2' },
        { label: 'city', key: 'addresses_city' },
        { label: 'state', key: 'addresses_stateOrRegion' },
        { label: 'zipcode', key: 'addresses_zipcode' },
        { label: 'country', key: 'addresses_country' },
        { label: 'email', key: 'emails_value' },
        { label: 'phone_number', key: 'phoneNumbers_value' },
        { label: 'payment_term', key: 'payment_terms_value' },
        { label: 'customer_class', key: 'customer_class_value' },
        { label: 'out_of_business', key: 'out_of_business' },
        { label: 'admin_notes', key: 'admin_notes' },
      ]
    },
    {
      name: 'Creators',
      query: QUERY_CREATORS,
      headers: [
        { label: 'id', key: 'id' },
        { label: 'display_name', key: 'display_name' },
        { label: 'payment_name', key: 'payment_name' },
        { label: 'payment_type', key: 'payment_type_value' },
        { label: 'email', key: 'emails_value' },
        { label: 'phone_number', key: 'phoneNumbers_value' },
        { label: 'address_line1', key: 'addresses_line1' },
        { label: 'address_line2', key: 'addresses_line2' },
        { label: 'city', key: 'addresses_city' },
        { label: 'state', key: 'addresses_stateOrRegion' },
        { label: 'country', key: 'addresses_country' },
        { label: 'zipcode', key: 'addresses_zipcode' },
        { label: 'biography', key: 'biography' },

      ]
    },
    {
      name: 'Expenses',
      query: QUERY_EXPENSES,
      headers: [
        { label: 'id', key: 'id' },
        { label: 'expense_date', key: 'expense_date' },
        { label: 'amount', key: 'amount' },
        { label: 'category', key: 'category_value'},
        { label: 'product_id', key: 'product_id' },
        { label: 'product_title', key: 'product_title' },
        { label: 'product_isbn', key: 'product_isbn' },
        { label: 'admin_notes', key: 'admin_notes' },
      ]
    },
    {
      name: 'Royalties',
      query: QUERY_ROYALTYS,
    },
    {
      name: 'RoyaltyPayments',
      query: QUERY_ROYALTY_PAYMENTS,
      headers: [
        { label: 'id', key: 'id' },
        { label: 'payment_date', key: 'sent_date' },
        { label: 'payment_method', key: 'method_value' },
        { label: 'amount', key: 'amount' },

        { label: 'product_id', key: 'product_id' },
        { label: 'product_title', key: 'product_title' },
        { label: 'product_isbn', key: 'product_isbn' },
        { label: 'creator_display_name', key: 'creator_display_name' },
        { label: 'creator_payment_name', key: 'creator_payment_name' },

      ]
    }
  ];

  const getQuery = queryTypes.filter(query => query.name === dataType).map(item => {
    return item.query;
  });

  const getHeaders = queryTypes.filter(query => query.name === dataType).map(item => {
    return item.headers;
  });

  const { data } = useQuery(getQuery[0], {
    variables: { publisher: parseInt(currentpublisher.id) },
    skip: !allMode,
  });

  const saniRec = (newdata) => {
    if (newdata) {
      let objects = [];
      const dataSet = Object.values(newdata).flat();
      dataSet.forEach(item => {
        const newOne = sanitizeRecord(JSON.parse(JSON.stringify(item)));
        objects.push(newOne);
      });
      let cleaned = clean(objects);
      return cleaned;
    }
  }

  const dataSetClean = saniRec(data);


  const filteredData = dataSetClean ? Object.values(dataSetClean).filter((val) => !props.excluded.find(({ id }) => val.id === id)) : [];

  const fetchData = () => {
    setFetched(filteredData);
  }

  const csvDataCleaned = saniRec(props.csvData);

  return (
    <Paragraph size={300}>
      {props.allmode ? (
        <CSVLink data={fetched} filename={filename} headers={getHeaders[0]} onClick={fetchData}>(Export Selected) </CSVLink>
      ) : (
        <CSVLink data={csvDataCleaned} filename={filename} headers={getHeaders[0]} >(Export Selected)</CSVLink>
      )}</Paragraph>
  )
}

export default CsvDownload;