import { gql } from 'apollo-boost';

const UPDATE_ROYALTY_PAYMENT_MUTATION = gql`
  mutation UpdateRoyaltyPaymentMutation(
    $id: Int!,
    $changed: String!
  ) {
    updateRoyaltyPayment(
      id: $id,
      changed: $changed
    ) {
      message
    }
  }
`;

export default UPDATE_ROYALTY_PAYMENT_MUTATION;