import { gql } from 'apollo-boost';

const CREATE_EXPENSE_CATEGORY_MUTATION = gql`
  mutation CreateExpenseCategoryMutation(
    $publisherId: Int!,
    $expenseCategory: String!
  ) {
    createExpenseCategory(
      publisherId: $publisherId,
      expenseCategory: $expenseCategory
    ) {
      message
    }
  }
`;

export default CREATE_EXPENSE_CATEGORY_MUTATION;