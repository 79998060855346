import { gql } from 'apollo-boost';

const QUERY_PUBLISHER_USERS = gql`
  query PublisherUsers($publisher: Int!) {
    publisherusers(publisher: $publisher) {
      id
      status
      publisher {
        id,
        name
      }
      user {
        id,
        email
      }
      role {
        id,
        name
      }
    }
    userroles {
      id
      name
    }
    publisherinvitations(publisher: $publisher) {
      id
      invite {
        email,
        key
      }
    }
  }
`;

export default QUERY_PUBLISHER_USERS;