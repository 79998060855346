import React, { useState, useEffect, useCallback} from "react";

import {
  Pane,
  Paragraph,
  TextInput,
  Button,
  IconButton,
  Checkbox,
  Combobox,
  SelectMenu,
  AddIcon,
  Badge,
  ChevronDownIcon,
  Table,
  CrossIcon,
} from 'evergreen-ui';

export const ForeignKeyDropDownInput = props => {
  const onixTypes = [
    "format",
    "format__description",
    "formatDetail",
    "status",
    "status__description",
    "audience",
    "onixContributorCode"
  ];
  const isOnix = onixTypes.indexOf(props.el) > -1 ? true : false;
  const makeItemList = arr => {
    const newArr = [];
    arr.forEach((item, idx) => {
      if (item) {
        let newItem = Object.assign({}, item)
        if (isOnix) {
          newItem["label"] = `${item.description}`;
        } else if (props.el === "creator") {
          newItem["label"] = `${item.displayName} (id: ${item.id})`;
        } else {
          newItem["label"] = item.value;
        }
        newArr.push(newItem);
      }
    });
    return newArr;
  }

  const [items, setItems] = useState([]);
  const [current, setCurrent] = useState("");
  const [newmode, setNewmode] = useState(false);
  const [newval, setNewval] = useState("");
  const [currentobj, setCurrentobj] = useState({ label: "" });

  const handleChange = item => {
    delete item.label;
    props.handleChange(item);
  }

  const getObj = selected => {
    if (props.el === "onixContributorCode" || props.el === "creator") {
      return { key: props.el, val: selected, id: props.creatorId };
    } else {
      return { key: props.el, val: selected };
    }
  }

  const shouldShowLabel = (props.opts && props.opts.hasOwnProperty("showLabel")) ? props.opts.showLabel : true;

  const getValWithLabel = () => {
    return makeItemList([props.value])[0];
  }

  const handleNewItem = val => {
    props.handleAddNew(newval);
    let valWithLabel = getValWithLabel({ id: '0', value: val });
    setNewval("");
    setNewmode(false);
  }

  const getCurrent = () => {
    let valWithLabel = getValWithLabel();
    if (isOnix) {
      return valWithLabel.label;
    } else if (props.el === "creator") {
      return props.value.displayName;
    } else {
      return props.value.value;
    }
  }

  const mandatory = props.opts && props.opts.mandatory && props.editing && !current
    ? true
    : false;

  useEffect(() => {
    if (props.values) {
      setItems(makeItemList(props.values));
    }
    if (props.value) {
      let valWithLabel = getValWithLabel();
      let tmpCurrent = getCurrent();
      setCurrent(tmpCurrent);
      setCurrentobj(valWithLabel);
    }
  }, [props.values, props.value]);

  return (
    <Pane
      width={props.width}
      marginBottom={shouldShowLabel ? props.opts.short ? 0 : 16 : 0}
      marginRight={(props.opts && props.opts.hasOwnProperty("marginRight") ? props.opts.marginRight : 0)}
      key={`${props.el.replace("\s", "")}`}
      display={props.opts.badge ? "flex" : "block"}
      alignItems={props.opts.badge ? "center" : "flex-start"}

    >
      {shouldShowLabel && (
        <Paragraph marginBottom={ props.opts.badge || props.opts.short ? 0 : 8} marginRight={props.opts.badge ? 4 : 0}size={300} color="#667b7f">{props.formLabel}</Paragraph>

      )}
      {props.editing ? (
        <Pane>
        <Pane display="flex" flexDirection="row" alignItems="center" marginBottom={8}>
        <Combobox
          key={current ? `${current}-${props.el}-picker` : `${props.el}-picker`}
          initialSelectedItem={getValWithLabel()}
          items={items}
          itemToString={item => (item ? item.label : '')}
          onChange={selected => handleChange(getObj(selected))}
          marginBottom={shouldShowLabel ? props.opts.badge || props.opts.new ? 0 : 8 : 0}
          width={props.opts.new ? props.width - 90 : props.width}
          height={props.opts.new ? props.opts.short ? 20 : 28 : 32}
        />
        {!newmode && props.opts.new && <Button size="small" marginLeft={4} onClick={e => setNewmode(true)}>Add New</Button>}

        </Pane>
        {newmode && props.opts.new && (
            <Pane display="flex" flexDirection="row" alignItems="center">
              <TextInput
                height={24}
                className="item-form"
                value={newval}
                width={props.width - 50}
                onChange={e => setNewval(e.target.value)}
              />
              <Pane display="flex">
                <IconButton size="small" disabled={!newval} icon={AddIcon} intent="success" marginLeft={8} onClick={e => handleNewItem(newval)} />
                <IconButton size="small" icon={CrossIcon} intent="danger" marginLeft={8} onClick={e => setNewmode(false)} />
              </Pane>
            </Pane>
          )}
          </Pane>
      ) : props.opts.badge ? (<Badge>{current}</Badge>
      ) : (
        <TextInput
          height={props.opts.short ? 20 : 32}
          className="item-form"
          value={current}
          disabled={true}
          width={props.width}
        />
      )}
      {mandatory && (
        <Paragraph color="red" size={400} marginLeft={4}>*</Paragraph>
      )}
    </Pane>
  );
}

export const ForeignKeyMultiSelectDropDownInput = props => {
  const onixTypes = [
    "format",
    "format__value",
    "format__description",
    "formatDetail",
    "status",
    "status__value",
    "status__description",
    "audience",
    "onixContributorCode"
  ];
  const isOnix = onixTypes.indexOf(props.el) > -1 ? true : false;

  const makeItemList = useCallback(arr => {
    const newArr = [];
    arr.forEach((item, idx) => {
      if (item) {
        let newItem = Object.assign({}, item)
        if (isOnix) {
          newItem["label"] = `${item.description}`;
        } else if (props.el === "creator") {
          newItem["label"] = `${item.displayName} (id: ${item.id})`;
        } else {
          let value = item.value === "" ? "None" : item.value;
          newItem["label"] = value
          newItem.value = value
        }
        newArr.push(newItem);
      }
    });
    return newArr;
  }, [isOnix, props.el])

  const [items, setItems] = useState([]);
  const [exclude, setExclude] = useState(false);

  const handleChange = (item, checked) => {

    let index = item.indexOf("None");
    let itemval = [...item]

    if (index !== -1) {
      itemval[index] = "";
    }
    if (item.length > 0) {
      props.handleChange({ key: props.el, val: itemval, exclude: checked });
    } else {
      props.handleChange({ key: props.el, val: [], exclude: checked });
    }
  }

  const handleCheck = (checked) => {
    props.setDetails({ ...props.details, [props.formLabel]: { exclude: checked, labels: selectedLabels } })
    handleChange(selectedItemsState, checked)
    setExclude(checked)
  }

  useEffect(() => {
    if (props.values) {
      setItems(makeItemList(props.values));
    }
    if (props.value === "") {
      setSelectedItems([])
      setSelectedLabels([])
      setExclude(false)
    }
  }, [props.values, props.value, makeItemList]);

  const [selectedItemsState, setSelectedItems] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);

  return (
    <Pane
      marginRight={(props.marginRight ? props.marginRight : 0)}
      key={`${props.el.replace("\s", "")}`}
    >
      <SelectMenu
        titleView={({ close, title, headerHeight }) => {
          return (
            <Pane
              display="flex"
              alignItems="center"
              borderBottom="default"
              padding={8}
              height={headerHeight}
              boxSizing="border-box"
            >
              <Checkbox label="Exclude Selected?" checked={exclude} onChange={e => handleCheck(e.target.checked)} margin={0} />
            </Pane>
          )
        }}
        isMultiSelect
        options={items}
        selected={selectedItemsState}
        onSelect={(item) => {
          const selected = [...selectedItemsState, item.value];
          const selectedItems = selected;
          const labels = [...selectedLabels, item.label]
          setSelectedItems(selectedItems);
          setSelectedLabels(labels);
          props.setDetails({ ...props.details, [props.formLabel]: { exclude: exclude, labels: labels } })
          handleChange(selectedItems, exclude);

        }}
        onDeselect={(item) => {
          const deselectedItemIndex = selectedItemsState.indexOf(item.value);
          const selectedItems = selectedItemsState.filter((_item, i) => i !== deselectedItemIndex);
          const labels = selectedLabels.filter(label => label !== item.label)
          setSelectedItems(selectedItems);
          setSelectedLabels(labels);
          props.setDetails({ ...props.details, [props.formLabel]: { exclude: exclude, labels: labels } })
          handleChange(selectedItems, exclude);

        }}
      >
        <Button iconAfter={ChevronDownIcon} marginTop={4}
          className={selectedItemsState.length > 0 ? exclude ? "exclude-button" : "include-button" : ""} >
          {props.formLabel} ({selectedItemsState.length})
        </Button>
      </SelectMenu>
    </Pane>
  )
}

export const AddNewForeignKey = props => {
  const [items, setItems] = useState([]);
  const [newmode, setNewmode] = useState(false);
  const [newval, setNewval] = useState("");

  const handleNewItem = val => {
    props.handleAddNew(newval, items, props.el);
    setNewval("");
    setNewmode(false);
  }

  useEffect(() => {
    if (props.values) {
      setItems(props.values);
    }
  }, [props.values]);

  return (
    <Pane margin={16}>
      <Paragraph marginBottom={8} size={300} color="grey"> {props.formLabel} ({items.length})
        {!newmode &&
          <Button marginLeft={8} size="small" intent="success" onClick={e => setNewmode(true)}>Add New</Button>
        }
      </Paragraph>
      {newmode && (
        <Pane display="flex" flexDirection="row" alignItems="center">
          <TextInput
            height={24}
            className="item-form"
            value={newval}
            width={props.width - 50}
            onChange={e => setNewval(e.target.value)}
          />
          <IconButton size="small" disabled={!(newval.length > 0)} icon={AddIcon} intent="success" appearance="primary" marginLeft={8} onClick={e => handleNewItem(newval)} />
          <IconButton size="small" icon={CrossIcon} intent="danger" marginLeft={8} onClick={e => { setNewmode(false); setNewval("") }} />

        </Pane>
      )}
      <Pane maxHeight={150} overflowY="scroll">
        {items.map((item, index) => (
          <Table.Row key={item.value} height={28} backgroundColor={
            index % 2 === 0 ? "#f9faff" : "#F4F5F9"} >
            <Table.TextCell>
              {item.value}
            </Table.TextCell>
          </Table.Row>
        ))}

      </Pane>

    </Pane>
  );
}
