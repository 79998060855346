import { gql } from 'apollo-boost';

const QUERY_ROYALTY_PAYMENTS = gql`
  query RoyaltyPayments($publisher: Int!, $page: Int, $params: String) {
    royaltypayments(publisher: $publisher, page: $page, params: $params) {
      id,
      amount,
      sentDate,
      method {
        id,
        value
      },
      royalty {
        id,
        product {
          id,
          title,
          isbn,
          format {
            id,
            value,
            description
          },
        },
        creator {
          id,
          displayName,
          photoUrl,
          paymentName,
          paymentType {
            id,
            value
          }
        },
        locked,
        lastEditor {
          id,
          email
        },
        created,
        modified,
      },
      created,
      modified,
    }
  }
`;

export default QUERY_ROYALTY_PAYMENTS;