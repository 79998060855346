import { gql } from 'apollo-boost';

const CREATE_SALESPERSON_MUTATION = gql`
  mutation CreateSalespersonMutation(
    $publisherId: Int!,
    $salesperson: String!
  ) {
    createSalesperson(
      publisherId: $publisherId,
      salesperson: $salesperson
    ) {
      message
    }
  }
`;

export default CREATE_SALESPERSON_MUTATION;