import React, { useState } from "react";
import { useMutation } from 'react-apollo';

// ------- FUNCTIONS
import { makeDateString, makeInputs } from '../../util.js';

// ------- MUTATIONS
import CREATE_ORDER_MUTATION from "../../graphql/mutations/Orders/CreateOrder.js";
import CREATE_ORDER_PRODUCT_MUTATION from "../../graphql/mutations/Orders/CreateOrderProduct.js";

// ------- COMPONENTS
import { baseOrderObject } from './BaseOrderObject.js';
import OrderCustomer from "./OrderCustomer.js";
import OrderDetails from "./OrderDetails.js";
import OrderPaymentStatus from "./OrderPaymentStatus.js";
import ProductBox from "./ProductBox.js";

import {
  Pane,
  Card,
  Paragraph,
  Button,
  toaster,
  Spinner,
  Checkbox,
} from 'evergreen-ui';

import { DateFieldInput, TextInputFieldInput } from "../../inputs/TextInputs.js";

function NewOrder(props) {
  const [staged, setStaged] = useState(baseOrderObject);
  const [stagedProducts, setStagedProducts] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [createOrder, { orderdata }] = useMutation(CREATE_ORDER_MUTATION);
  const [createOrderProduct, { createdata }] = useMutation(CREATE_ORDER_PRODUCT_MUTATION);

  const handleClearStaged = () => {
    setStaged(baseOrderObject);
    setStagedProducts([]);
  }
  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
    if (obj.hasOwnProperty("isValid")) {
      checkIfValid(stagedProducts)
    }

  }

  const handleCreateOrder = async () => {
    let newProduct = { ...staged };
    if (newProduct.hasOwnProperty("orderproductSet")) {
      delete newProduct.orderproductSet;
    }
    if (newProduct.hasOwnProperty("orderpaymentSet")) {
      delete newProduct.orderpaymentSet;
    }
    if (newProduct.shipDate) {
      newProduct = { ...newProduct, shipDate: makeDateString(newProduct.shipDate) };
    }
    if (newProduct.shipped === true && newProduct.shipDate === '') {
      const today = new Date();
      const todayDate = makeDateString(today);
      newProduct = { ...newProduct, shipDate: todayDate }
    }
    if (newProduct.paidDate) {
      newProduct = { ...newProduct, paidDate: makeDateString(newProduct.paidDate) };
    }
    if (newProduct.paid === true && newProduct.paidDate === '') {
      const today = new Date();
      const todayDate = makeDateString(today);
      newProduct = { ...newProduct, paidDate: todayDate }
    }
    const results = await createOrder(
      {
        variables: {
          publisherId: parseInt(props.publisher),
          order: JSON.stringify(newProduct)
        }
      });

    const orderId = results.data.createOrder.message;

    // add any order info
    if (stagedProducts.length) {
      const allNew = await Promise.all(stagedProducts.map(async (orderprod) => {
        const params = Object.fromEntries(
          ['quantity', 'retailPrice', 'calcDiscount', 'calcPrice', 'discountPerUnit', 'netPrice']
            .filter(key => key in orderprod) // line can be removed to make it inclusive
            .map(key => [key, orderprod[key]])
        );

        const newprod = await createOrderProduct(
          {
            variables: {
              productId: parseInt(orderprod.product.id),
              orderId: parseInt(orderId),
              orderProduct: JSON.stringify(params),
            }
          });
        return newprod

      }))
      toaster.closeAll()
      toaster.success(`Added ${allNew.length} product(s) to order`, { duration: 10 })
    }

    handleClearStaged();
    props.handleToggleNewMode();
    props.handleRefetchOrders();
  }

  const inputs = {
    paidDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 1, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    shipDate: { width: 100, comp: DateFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 2, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },
    poNumber: { width: 100, comp: TextInputFieldInput, formLabel: "", handleChange: handleUpdateStaged, group: 3, opts: { labelDirection: "row", marginBottom: 0, marginLeft: 4 } },

  };

  const formInputs = makeInputs(staged, inputs, true);

  const unitCount = stagedProducts.map(item => parseInt(item.quantity)).reduce((partial_sum, a) => partial_sum + a, 0)

  const handleNewOrderProduct = (product, params) => {
    params.product = product;
    params.retailPrice = product.retailPrice;
    params.calcDiscount = 0

    if (params.discountPerUnit) { params.calcDiscount = params.discountPerUnit }

    if (params.netPrice) { params.calcDiscount = 100 * (params.retailPrice - params.netPrice) / (params.retailPrice) }
    params.calcPrice = params.retailPrice * (100 - params.calcDiscount) / 100

    let stagedArr = stagedProducts.concat(params)
    setStagedProducts(stagedArr)
    checkIfValid(stagedArr)
  }

  const handleUpdateOrderProduct = (item) => {
    let stagedArr = stagedProducts.filter(stagedItem => stagedItem.tmpid !== item.tmpid)
    stagedArr.push(item);
    checkIfValid(stagedArr)
    setStagedProducts(stagedArr)
  }

  const handleDeleteOrderProduct = (item) => {
    let newarr = stagedProducts.filter(product => product.tmpid !== item.tmpid);
    setStagedProducts(newarr);
    checkIfValid(newarr)
  }

  const handleChooseInfo = (itemString, itemType) => {
    let item = JSON.parse(itemString)
    handleUpdateStaged({ key: itemType, val: item });
  }


  const checkIfValid = arr => {
    let invalid = arr.some(item => {
      return (!item.quantity || parseInt(item.quantity) === 0)
    }) || arr.length < 1
    if (invalid) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }

  return (
    <Card position="absolute" display="flex" flexDirection="column" width="calc(100vw - 204px)" elevation={3} padding={16} backgroundColor="white" >
      <Pane id="heading-buttons" display="flex" justifyContent="space-between">
        <Pane display="flex" alignItems="flex-end">
          <Paragraph marginX={16} size={300} fontSize={18}>New Order</Paragraph>
          <Pane display="flex" alignItems="flex-end">
            <Paragraph marginLeft={8} size={300}>PO: </Paragraph>
            {formInputs.group3}
          </Pane>
          <Pane display="flex" alignItems="center">
            <Checkbox marginY={0} marginLeft={8} marginRight={4} label="PAID" checked={staged.paid}
              onChange={e => handleUpdateStaged({ key: 'paid', val: !staged.paid })} />
            <Pane>{formInputs.group1}</Pane>
            <Checkbox marginY={0} marginLeft={8} marginRight={4} label="SHIPPED" checked={staged.shipped}
              onChange={e => handleUpdateStaged({ key: 'shipped', val: !staged.shipped })} />
            <Pane>{formInputs.group2}</Pane>
          </Pane>
        </Pane>
        <Pane display="flex">
          <Button disabled={!canSave || orderdata?.loading || createdata?.loading} marginRight={16} onClick={e => {
            handleCreateOrder();
          }}>
            Save {(orderdata?.loading || createdata?.loading) && <Spinner marginLeft={8} size={12} />}
          </Button>
          <Button onClick={e => {
            handleClearStaged();
            props.handleToggleNewMode();
          }}>
            Cancel
          </Button>
        </Pane>
      </Pane>
        <Pane id="form-top" marginTop={8} display="flex" justifyContent="space-between">
          <OrderCustomer newMode order={staged} staged={staged} editing={true} handleChooseInfo={handleChooseInfo} />
          <OrderDetails handleUpdateStaged={handleUpdateStaged} staged={staged} editing={true} unitCount={unitCount} />
          <OrderPaymentStatus
            staged={staged}
            handleUpdateStaged={handleUpdateStaged}
            editing={true}
            stagedProducts={stagedProducts}
            
          />
        </Pane>
      <Pane overflow="auto">
        <ProductBox
          editing={true}
          orderId={false}
          orderproductSet={stagedProducts}
          handleNewOrderProduct={handleNewOrderProduct}
          handleUpdateOrderProduct={handleUpdateOrderProduct}
          handleDeleteOrderProduct={handleDeleteOrderProduct}
        />
      </Pane>
    </Card >


  )
}

export default NewOrder;
