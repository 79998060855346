import React, { useState, useEffect} from "react";
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';
import { getCookie } from '../../util.js';

import QUERY_CUSTOMERS from "../../graphql/queries/Customers/QueryCustomers.js";


import {
  Button,
  SelectMenu
} from 'evergreen-ui';

function AddCustomer(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(
    props.customer?.firstName ? props.customer.firstName +" "+props.customer.lastName+" ("+props.customer.company+")": false
  );

 
  const { loading, error, data, refetch} = useQuery(QUERY_CUSTOMERS, {
    variables: { publisher: parseInt(currentpublisher.id) },
  });


  
  useEffect(() => {
    if (data) {
      const csrftoken = getCookie('csrftoken');
      fetch('/count_customers/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken
        },
        body: JSON.stringify({ publisher: currentpublisher.id })
      })
        .then(res => res.json())
        .then(result => {
          if(parseInt(result.message) !== data.customers.length){
            refetch()
          } else {
            setCustomers(data.customers);
        }})
    }
  }, [data, currentpublisher.id, refetch]);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <SelectMenu
          hasTitle={false}
          options = {customers.map((item, index)=> ({
            key: `customer${item.id}`, 
            value: JSON.stringify(item),
            label: `${item.firstName} ${item.lastName} (${item.company})`, 
            }))
          }
          selected={customer}
          closeOnSelect={true}
          onSelect={(option)=> {
            setCustomer(option.label)
            props.handleChooseInfo(option.value, "customer")
            }}>
          <Button>{customer ? customer : 'Select Customer...'}</Button></SelectMenu>
    
  )
}

export default AddCustomer;