import React, { useState, useEffect } from "react";
import { useQuery, } from 'react-apollo';
import { Link } from 'react-router-dom';
import { useSelector, } from 'react-redux';
import { makeDateString, commaSepThou } from '../../../util.js';
import QUERY_TOP_TEN_BY_DATE from "../../../graphql/queries/QueryTopTenByDate.js";

import {
  Pane,
  Paragraph,
  Badge,
  EmptyState,
  InfoSignIcon
} from 'evergreen-ui';

import {
  DateFieldInput,
} from "../../../inputs/index.js";

const TopTenByDate = props => {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const currentpublisher = useSelector((state) => state.currentpublisher.value);

  const today = new Date();
  const prevMonth = new Date();
  const initStartDate = makeDateString(new Date(prevMonth.setDate(prevMonth.getDate() - 730)));
  const initEndDate = makeDateString(today);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [chartData, setChartData] = useState([]);

  const handleChangeDate = obj => {
    let newDate = makeDateString(obj.val);
    if (obj.key === "startDate") {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }
  }

  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";

  const { loading, error, data } = useQuery(QUERY_TOP_TEN_BY_DATE, {
    variables: { publisher: parseInt(currentpublisher.id), startDate: startDate, endDate: endDate },
  });

  useEffect(() => {
    if (data) {
      // sum orderProducts
      const summedObj = {};
      data.toptenbydate.forEach(item => {
        if (summedObj.hasOwnProperty(item.product.id)) {
          summedObj[item.product.id]["total"] = summedObj[item.product.id]["total"] + parseFloat(item.totalAmount);
        } else {

          summedObj[item.product.id] = {
            product_id: item.product.id,
            title: item.product.title,
            isbn: item.product.isbn,
            format: item.product.format ? item.product.format.description : "No Format",
            total: parseFloat(item.totalAmount)
          };
        }
      });

      // sum expenses by product
      const summedExpenses = {};
      data.expensesbydate.forEach(item => {
        if (item.product) {
          if (summedExpenses.hasOwnProperty(item.product.id)) {
            summedExpenses[item.product.id] = summedExpenses[item.product.id] + parseFloat(item.amount);
          } else {
            summedExpenses[item.product.id] = parseFloat(item.amount);
          }
        }
      });

      // // delete expenses from product totals
      const finalArr = [];
      for (let item in summedObj) {
        if (summedExpenses.hasOwnProperty(item)) {
          summedObj[item].total = (summedObj[item].total - summedExpenses[item]).toFixed(2)
        } else {
          summedObj[item].total = summedObj[item].total.toFixed(2)
        }

        finalArr.push(summedObj[item]);
      }
      finalArr.sort((a, b) => b.total - a.total);
      setChartData(finalArr);
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <Pane background="white" elevation={2} padding={16} border={"default"} marginRight={16}>
      <Paragraph size={500} fontWeight="bolder" color="var(--green)">Top 10 Products By Profit</Paragraph>
      <Pane display="flex" flexDirection="row" justifyContent="flex-start" marginY={8}>
        <Pane marginRight={32}>
        <DateFieldInput el="startDate" value={startDate} editing={true} handleChange={handleChangeDate} formLabel="Start Date" width={300} />

        </Pane>
        <DateFieldInput el="endDate" value={endDate} editing={true} handleChange={handleChangeDate} formLabel="End Date" width={300} />
      </Pane>
      {data && data.toptenbydate.length > 0 ? 
      <Pane>
      
      {chartData.slice(0, 10).map((item, index) => {
        return (
          <Pane key={item.title + item.format} marginBottom={4}>
            <Paragraph size={300} color={item.total < 0 ? "red" : "black"}>{index + 1}. {item.total<  0 ? "-" : ""}{currency}{commaSepThou(Math.abs(item.total))}: <Link to={`/products/${item.product_id}`}>{item.title}</Link> <Badge>{item.format}</Badge></Paragraph>
          </Pane>
        );
      })}

      </Pane> :
      <Pane height={200} width={300}>
      <EmptyState
        background="light"
        title="No Sales Recorded for Selected Dates"
        orientation="vertical"
        icon={<InfoSignIcon size={12} color="#C1C4D6" />}
        iconBgColor="#EDEFF5"
      />
    </Pane>
      }
      
      
    </Pane>
  );
}

export default TopTenByDate;
