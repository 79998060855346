import { gql } from 'apollo-boost';

const DELETE_ORDER_PRODUCT_MUTATION = gql`
  mutation DeleteOrderProductMutation(
    $id: Int!
  ) {
    deleteOrderProduct(
      id: $id
    ) {
      message
    }
  }
`;

export default DELETE_ORDER_PRODUCT_MUTATION;