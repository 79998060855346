import React from "react";
import { useMutation } from 'react-apollo';
import { useSelector, useDispatch } from 'react-redux';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';
import { makeInputs } from '../../util.js';
import CREATE_PAYMENT_TYPE_MUTATION from "../../graphql/mutations/PublisherData/CreatePaymentType.js";

import { 
  TextInputFieldInput,
  TextareaFieldInput,
  ForeignKeyDropDownInput,
} from "../../inputs/index.js";

import {
  Pane,
} from 'evergreen-ui';

function CreatorForm(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const dispatch = useDispatch();

  const [createPayment, { paymentdata }] = useMutation(CREATE_PAYMENT_TYPE_MUTATION);

  const handleAddNewPaymentType = async (val) => {
    const paymenttypes = [].concat(publisherGlobalData.paymenttypes);
    
    const results = await createPayment(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          paymentType: val,
        }
      });


    const newType = { id: results.data.createPaymentType.message, value: val }
    paymenttypes.push(newType);
    dispatch(setpublisherGlobalData({ paymenttypes: paymenttypes }));
    props.handleUpdateStaged({ key: "paymentType", val: newType });
  }

  const inputs = {
    displayName: { width: 200, comp: TextInputFieldInput, formLabel: "Display Name", handleChange: props.handleUpdateStaged, group: 1, opts: { marginRight: 32 } },
    paymentName: { width: 200, comp: TextInputFieldInput, formLabel: "Payment Name", handleChange: props.handleUpdateStaged, group: 1 },
    residence: { width: 200, comp: TextInputFieldInput, formLabel: "Residence", handleChange: props.handleUpdateStaged, group: 2, opts: { marginRight: 32 } },
    biography: { width: 400, comp: TextareaFieldInput, formLabel: "Biography", handleChange: props.handleUpdateStaged, group: 3 },
    paymentType: { width: 200, comp: ForeignKeyDropDownInput, formLabel: "Preferred Payment Method", handleChange: props.handleUpdateStaged, handleAddNew: handleAddNewPaymentType, group: 2, values: publisherGlobalData.paymenttypes, opts: {new:true } },
  }

  // loop over the staged array to make the columns
  const formInputs = makeInputs(props.staged, inputs, props.editing);

  return (
    <Pane>
      <Pane display="flex"  >
        {formInputs.group1}
      </Pane>
      <Pane display="flex"  >
        {formInputs.group2}
      </Pane>
        {formInputs.group3}
    </Pane>
  )
}

export default CreatorForm;