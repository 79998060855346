// Rewrite documentation, mutation result handline
import React, { useState, useEffect } from "react";
import { Prompt, useHistory,} from "react-router-dom";
import { useMutation } from 'react-apollo';
import LOCK_ITEM_MUTATION from "../graphql/mutations/LockItem.js";
import { Dialog, Pane, } from 'evergreen-ui';

/**
 * Display custom dialog to black navigation to another page
 * 
 * Wraps react-router-dom's Prompt to create customizable dialog and allows
 * callback function to be invoked on confirmation. Used example from this
 * article as foundation:
 *
 * https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 *
 * Used above method rather than react-router-dom's getUserConfirmation
 * (https://v5.reactrouter.com/web/api/BrowserRouter/getuserconfirmation-func)
 * ... as getUserConfirmation does not have obvious way to hook function
 * into confirmation
 */
function LeavePagePrompt(props) {
  const [isShown, setIsShown] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const [lockItem, { lockdata }] = useMutation(LOCK_ITEM_MUTATION);
  const {userid, itemid, itemType} = props;
  const history = useHistory();

  useEffect(() => {
    if (lastLocation && confirmedNavigation) {
     lockItem(
        { variables: {
          userId: parseInt(userid),
          itemType: itemType,  
          itemId: parseInt(itemid),
          status: false,
        }
      });
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, userid, itemid, itemType, lockItem, history]);

  const handleBlockedNavigation = (nextLocation) => {
    console.log('when', props.when, 'confirmedNavigation', confirmedNavigation, isShown);
    if (!confirmedNavigation && props.when) {
      setIsShown(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    if (lastLocation) {
      setConfirmedNavigation(true);
    }
  };

  const handleCancelClick = (close) => {
    setIsShown(false);
    close(); 
  }

  return (
    <Pane>
      <Prompt when={props.when} message={handleBlockedNavigation} />
      <Dialog
        isShown={isShown}
        title={props.title ?? "You have unsaved changes."} 
        onConfirm={handleConfirmNavigationClick}
        onCancel={handleCancelClick}
      >
        {props.message ?? "Leave page without saving?"}
      </Dialog>
    </Pane>
  );
}

export default LeavePagePrompt
