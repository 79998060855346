import React from "react";
import ReactDOM from 'react-dom';
import {Route, Switch, BrowserRouter, Redirect,} from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import store from './store/store.js'
import { Provider } from 'react-redux'
import App from './App';
import Login from "./views/Login/Login.js";
import AcceptInvite from "./views/AcceptInvite.js";
import reportWebVitals from './reportWebVitals';
import './assets/styles/index.css';


const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_GRAPHQL_URL : window.location.protocol + '//' + window.location.hostname

const client = new ApolloClient({
  uri: baseUrl + '/graphql/',
});

const checkAuthentication = () => {
	if (localStorage.getItem('token') !== null) {
    return true;
  } else {
    return false;
  }
}

const checkUserid = () => {
  if (localStorage.getItem('userid') !== null) {
    return localStorage.getItem('userid');
  } else {
    return false;
  }
}

const checkPublisher = () => {
  if (localStorage.getItem('publisher') !== null) {
    return localStorage.getItem('publisher');
  } else {
    return false;
  }
}

const checkPublisherName = () => {
  if (localStorage.getItem('publisher_name') !== null) {
    return localStorage.getItem('publisher_name');
  } else {
    return false;
  }
}

const isAuthenticated = checkAuthentication();
const user = checkUserid();
const publisher = checkPublisher();
const publisher_name = checkPublisherName();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>

          <Route path="/accept_invite">
            <AcceptInvite />
          </Route>
          
          <Route path="/">
            {isAuthenticated ? (
              <App isAuthenticated={isAuthenticated} user={user} publisher={publisher} publisherName={publisher_name} baseUrl={baseUrl} />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        </Switch>
      </ApolloProvider>
    </Provider>
  </BrowserRouter>
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
