import React from "react";
import { useMutation } from 'react-apollo';
import { useSelector, useDispatch } from 'react-redux';
import { setpublisherGlobalData } from '../../redux/currentpublisher/publisherGlobalDataSlice.js';

// ------- FUNCTIONS
import { makeInputs } from '../../util.js';

// ------- QUERIES/MUTATIONS
import CREATE_CUSTOMERCLASS_MUTATION from "../../graphql/mutations/PublisherData/CreateCustomerClass.js";
import CREATE_PAYMENTTERM_MUTATION from "../../graphql/mutations/PublisherData/CreatePaymentTerm.js";

// ------- COMPONENTS
import {
  ForeignKeyDropDownInput,
  TextInputFieldInput,
  TextareaFieldInput,
  CheckboxInput,
} from "../../inputs/index.js";

import {
  Pane,
  Paragraph,
} from 'evergreen-ui'

function CustomerForm(props) {
  const currentpublisher = useSelector((state) => state.currentpublisher.value);
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);

  const dispatch = useDispatch();

  const [createCustomerClass, { classdata }] = useMutation(CREATE_CUSTOMERCLASS_MUTATION);

  const handleAddNewCustomerClass = async (val) => {
    const classes = [].concat(publisherGlobalData.customerclasses);
    const results = await createCustomerClass(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          customerClass: val,
        }
      });

    const newClass = { id: results.data.createCustomerClass.message, value: val }
    classes.push(newClass);
    dispatch(setpublisherGlobalData({ customerclasses: classes }));
    props.handleUpdateStaged({ key: "customerClass", val: newClass });
  }

  const [createPaymentTerm, { termdata }] = useMutation(CREATE_PAYMENTTERM_MUTATION);

  const handleAddNewPaymentTerm = async (val) => {
    const terms = [].concat(publisherGlobalData.paymentterms);
    const results = await createPaymentTerm(
      {
        variables: {
          publisherId: parseInt(currentpublisher.id),
          paymentTerm: val,
        }
      });

    const newTerm = { id: results.data.createPaymentTerm.message, value: val }
    terms.push(newTerm);
    dispatch(setpublisherGlobalData({ paymentterms: terms }));
    props.handleUpdateStaged({ key: "paymentTerm", val: newTerm });
  }

  const inputs = {
    firstName: { width: 200, comp: TextInputFieldInput, formLabel: "First Name", handleChange: props.handleUpdateStaged, group: 1, opts: { marginRight: 32 } },
    lastName: { width: 200, comp: TextInputFieldInput, formLabel: "Last Name", handleChange: props.handleUpdateStaged, group: 1 },
    company: { width: 400, comp: TextInputFieldInput, formLabel: "Company Name", handleChange: props.handleUpdateStaged, group: 11 },
    adminNotes: { width: 400, comp: TextareaFieldInput, formLabel: "Account Notes", handleChange: props.handleUpdateStaged, group: 3 },
    outOfBusiness: { width: 200, comp: CheckboxInput, formLabel: "Out Of Business?", handleChange: props.handleUpdateStaged, group: 22 },
    customerClass: { width: 200, comp: ForeignKeyDropDownInput, formLabel: "Customer Class", handleChange: props.handleUpdateStaged, handleAddNew: handleAddNewCustomerClass, group: 2, values: publisherGlobalData.customerclasses, opts: {new:true} },
    paymentTerms: { width: 200, comp: ForeignKeyDropDownInput, formLabel: "Payment Terms", handleChange: props.handleUpdateStaged, handleAddNew: handleAddNewPaymentTerm, group: 2, values: publisherGlobalData.paymentterms, opts: { marginRight: 32, new:true } },
  }

  // loop over the staged array to make the columns
  const formInputs = makeInputs(props.staged, inputs, props.editing);

  return (
    <Pane>
      <Pane display="flex" justifyContent="space-between" >
        <Paragraph size={400} fontWeight="bold" color="#5e5e5e">CUSTOMER</Paragraph>
        <Pane marginTop={4}>          {formInputs.group22}
        </Pane>
      </Pane>

      {formInputs.group11}

      <Pane display="flex">
        {formInputs.group1}
      </Pane>

      <Pane display="flex">
        {formInputs.group2}
      </Pane>
      {formInputs.group3}
    </Pane>
  )
}

export default CustomerForm;