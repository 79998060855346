import { gql } from 'apollo-boost';

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrderMutation(
    $publisherId: Int!,
    $order: String!
  ) {
    createOrder(
      publisherId: $publisherId,
      order: $order
    ) {
      message
    }
  }
`;

export default CREATE_ORDER_MUTATION;