
export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const processRecord = item => {
  for (let k in item) {
    if (item[k] && typeof item[k] === "object") {
      if (Array.isArray(item[k])) {
        for (let i = 0; i < item[k].length; i++) {
          let newitem = processRecord(item[k][i]);
          delete newitem["__typename"];
          item[k].splice(i, 1, JSON.stringify(newitem).replace(/"/g, "'").replace(/\\/g, ""));
        }
      } else {
        delete item[k]["__typename"];
        item[k] = JSON.stringify(item[k]).replace(/"/g, "'").replace(/\\/g, "");
      }
    }
  }
  return item;
}

export const sanitizeRecord = item => {
  // convert nested objects into strings
  item = processRecord(item);
  delete item["__typename"];
  return item;
}

export const makeInputs = (inputObj, inputs, editing) => {
  let groups = Object.keys(inputs).map(key => (inputs[key].group)).filter((value, index, self) => self.indexOf(value) === index);
  let obj = {};
  groups.map(group => obj[`group${group}`] = [])

  for (let key in inputObj) {
    if (inputs.hasOwnProperty(key)) {
      let myprops = inputs[key];
      obj[`group${myprops.group}`]
        .push(myprops.comp({ ...myprops, el: key, value: inputObj[key], editing: editing }));
    }
  }
  return obj;
}


export const makeAccountInputs = (inputs, editing) => {
  let groups = Object.keys(inputs).map(key => (inputs[key].group)).filter((value, index, self) => self.indexOf(value) === index);
  let obj = {};
  groups.map(group => obj[`group${group}`] = [])

  for (let key in inputs) {
    let myprops = inputs[key];
    obj[`group${myprops.group}`]
      .push(myprops.comp({ ...myprops, el: key, editing: editing }));
  }
  return obj;
}

export const csvToArray = (dataString) => {
  const rows = dataString.split(/\r?\n/);

  const result = rows.map(row => {
    const columns = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
    return columns.map(column => column.replace(/(^"|"$)/g, '').replace(/""/g, '"'));

  });
  return result

  // var lines = dataString
  //   .split(/\n/)                     // Convert to one string per line
  //   .map(function (lineStr) {
  //     return lineStr.split(",");   // Convert each line to array (,)
  //   })
  // return lines
}

export const arrayToCSV = (data) => {
  const csvRows = [];

  // Function to properly escape and enclose a field in double quotes if needed
  const escapeCSVField = (field) => {
    if (typeof field === 'string' && field.includes(',')) {
      return `"${field.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
    } else {
      return field;
    }
  };

  // Iterate through each row in the array
  for (const row of data) {
    // Convert each field in the row to a properly formatted CSV field
    const csvFields = row.map(escapeCSVField);

    // Join the CSV fields and add the row to the CSV rows array
    csvRows.push(csvFields.join(','));
  }

  // Join the CSV rows with newline characters to create the final CSV string
  return csvRows.join('\n');
}

export const sumArray = arr => {
  let sum = 0;
  if (arr && arr.length > 0) {
    sum = arr.reduce((partial_sum, a) => {
      if (!isNaN(parseFloat(a))) {
        return partial_sum + parseFloat(a);
      } else {
        return partial_sum + 0;
      }
    });
  }
  sum = !sum ? 0 : sum;
  return sum;
}

export const calculateDiscount = discount => {
  let res = Math.ceil(100 - parseFloat(discount)) * 0.01;
  if (isNaN(res)) {
    res = 1;
  }
  return res;
}

export const getTotalDuePerOrder = order => {
  let discount = calculateDiscount(order.discountPerUnit);
  let actualPrice = order.netPrice
    ? order.netPrice
    : order.product.retailPrice;
  let total = Math.round(discount * parseFloat(actualPrice) * parseFloat(order.quantity) * 100) / 100;
  return total;
}

export const getActualAmountArr = (arr, price) => {
  let amountArr = arr.map(item => {
    let actualPrice = price !== undefined
      ? price
      : item.netPrice
        ? item.netPrice
        : item.discountPerUnit
          ? item.product.retailPrice
          : item.wholesale && item.product.wholesalePrice
            ? item.product.wholesalePrice
            : item.product.retailPrice;
    let discount = item.discountPerUnit ? calculateDiscount(item.discountPerUnit) : 1;
    let quantity = item.quantity ? parseFloat(item.quantity) : 0;
    return discount * parseFloat(actualPrice) * quantity;
  });
  return amountArr;
}

export const makeDateString = date => {
  if (!date) {
    date = new Date();
  }
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0');
  let yyyy = date.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

export const numbersOnly = field => {
  if (field) {
    const regex = /^\d+\.?\d?\d?$/.test(field);
    return regex;
  } else {
    return false;
  }
}

export const makeAdjustedString = str => {
  str = str.replace(/\"/g, "'")
  if (str[0] === "'" && str[str.length - 1] === "'") {
    str = str.slice(1, -1)
  }
  return str.replace(/\:'\{/g, ":{")
    .replace(/\}'/g, "}")
    .replace(/\'\{/g, "}")
    .replace(/\\/g, "")
    .replace(/([a-zA-Z0-9])(')([a-zA-Z0-9])/g, "$1\\'$3");
}

// TODO: Clean this function up
export const checkValidity = (params, staged) => {
  let isValid = false;
  params.forEach(item => {
    if (item.required === true) {
      if (item.fieldType === "object") {
        if (
          staged.hasOwnProperty(item["name"]) &&
          staged[item["name"]].hasOwnProperty(item["field"]) &&
          staged[item["name"]][item["field"]] !== ""
        ) {
          isValid = true;
        }
      } else if (item.fieldType === "text") {
        if (staged.hasOwnProperty(item["name"]) && staged[item["name"]]) {
          isValid = true;
        }
      }
    }
  });
  return isValid;
}

// TODO: Consolidate updatePrimary functions
export const updatePrimary = (obj, stagedSet) => {
  let isPrimary = [].concat(stagedSet); // make copy of staged array
  if (obj.primary && obj.primary === true) { // if new/updated object is the primary
    isPrimary = isPrimary.map(item => {
      let clone = Object.assign({}, item);
      if (clone.primary && clone.primary === true) {
        clone.primary = false;
      }
      return clone;
    })
  } // make every other object in the staged array not the primary. why not just push the object here?
  return isPrimary;
}

export const updatePrimaryAndAddChanged = (obj, stagedSet, arr) => {
  let isPrimary = [];
  if (obj.primary && obj.primary === true) { // if new/updated object is the primary
    isPrimary = Object.assign([], stagedSet); // create copy of staged array
    isPrimary = isPrimary.filter(item => item.primary && item.primary === true); // filter staged array for current primary items
    isPrimary = isPrimary.map(item => { // for each current primary item
      let clone = Object.assign({}, item);
      if (clone.tmpid) {
        arr = arr.filter(old => old.tmpid !== clone.tmpid); // filter them out of changed array if they currently exist as 

      } else {
        arr = arr.filter(old => old.id !== clone.id)
      }
      clone.primary = false;
      return clone;
    });
  }
  return arr.concat(isPrimary);
}

export const updatePrimaryContactInfo = (stagedSet, changedSet) => {
  let stagedCopy = [].concat(stagedSet);
  let stagedUpdate = []
  let changedUpdate = []
  stagedCopy.forEach(item => {
    let clone = Object.assign({}, item);
    if (clone.primary && clone.primary === true) {
      if (clone.tmpid) {
        changedSet = changedSet.filter(old => old.tmpid !== clone.tmpid);
      } else {
        changedSet = changedSet.filter(old => old.id !== clone.id)
      }
      clone.primary = false;
      changedUpdate.push(clone);
    }
    stagedUpdate.push(clone)
  })
  changedUpdate = changedUpdate.concat(changedSet)
  return [stagedUpdate, changedUpdate]

}

export const updateStagedSet = (obj, stagedArr, setStaged, changedArr, setChanged, setName, fieldType) => {
  if (obj.tmpid) {
    stagedArr = stagedArr.filter(item => item.tmpid !== obj.tmpid);
    changedArr = changedArr.filter(item => item.tmpid !== obj.tmpid);
  } else {
    stagedArr = stagedArr.filter(item => item.id !== obj.id);
    changedArr = changedArr.filter(item => item.id !== obj.id);
  }

  if (obj.primary && obj.primary === true) {
    let updatedSets = updatePrimaryContactInfo(stagedArr, changedArr)
    stagedArr = updatedSets[0]
    changedArr = updatedSets[1]
  }

  changedArr.push(obj)
  stagedArr.push(obj)

  // setChanged(changedArr)
  setChanged(oldState => ({ ...oldState, [fieldType]: changedArr }))
  setStaged(oldState => ({ ...oldState, [setName]: stagedArr }))

}


export const getLocationQuery = (locState, setParams) => {
  let params = {}
  let query = locState.query
  for (let k in query) {
    let v = query[k]
      === 'true'
      ? true
      : query[k] === 'false'
        ? false
        : query[k];
    if (k.match("created")) {
      v = new Date(parseInt(query[k]));
    }
    params[k] = v;
  }
  setParams(oldState => ({ ...oldState, ...params }));
}

export const commaSepThou = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const notifyUser = (result, toaster, loadmsg, callmsg) => {

  if (result.loading) {
    toaster.notify(loadmsg, { duration: 60 });
  }
  else if (result.called && !result.error) {
    toaster.closeAll();
    toaster.success(callmsg, { duration: 1 });

  } else {
    console.log(result)
  }

}

export const sortItems = (arr, val) => {
  function compare(a, b) {
    if (!a[val]) {
      return -1;
    }
    if (!b[val]) {
      return 1;
    }
    if (a[val] < b[val] || a[val] > b[val]) {
      return a[val] - b[val]
    }
    return 0;
  }
  return arr.sort(compare);
}
