import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import SignupForm from './Login/SignupForm.js';
import { getCookie } from '../util.js';

import {
  Pane,
  Checkbox,
  Button,
  Heading,
  toaster,
} from 'evergreen-ui';

function AcceptInvite(props) {
  // const [invite, setInvite] = React.useState(props.invite);
  // const [group, setGroup] = React.useState(props.group);
  // const [pubid, setPubid] = React.useState(props.pubid);
  const [emailmode, setEmailmode] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [pw, setPw] = React.useState('');
  const [pwconf, setPwconf] = React.useState('');
  const [checked, setChecked] = React.useState(false);

  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const { invite, group, pubid } = Object.fromEntries(urlSearchParams.entries());

  let history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      history.push('/dashboard');
      // window.location.replace('http://localhost:3000/dashboard');
    } else {
      setLoading(false);
    }
  }, [history]);

  const handleSignupFromInvite = (e, user) => {
    e.preventDefault();

    console.log(user);

    const csrftoken = getCookie('csrftoken');

    console.log(group);

    fetch('/accept_invite/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({key: invite})
    })
      .then(response => {
        console.log("invite accepted!");
        console.log(response);
        if (response.status === 409) {
          toaster.danger('Invitation has already been accepted.')
        } else {
          const thistoken = getCookie('csrftoken');
          fetch('/api/v1/auth/register/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': thistoken
            },
            body: JSON.stringify(user)
          })
            .then(res => res.json())
            .then(data => {
              if (data.key) {
                const newtoken = getCookie('csrftoken');
                console.log("creating publisher user");
                localStorage.clear();
                localStorage.setItem('token', data.key);
                localStorage.setItem('userid', data.user);
                console.log(pubid);
                localStorage.setItem('publisher', pubid);
                localStorage.setItem('publisher_name', group);
                console.log(data);
                fetch('/api/publisherusers/', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': newtoken
                  },
                  body: JSON.stringify({publisher: pubid, user: data.user, role: 2})
                })
                  .then(response => {
                    history.push('/dashboard');
                    // window.location.replace(`/dashboard`);
                  })
                  .catch(error => {
                    console.log(error);
                  });
              } else {
                setEmail('');
                setPw('');
                setPwconf('');
                localStorage.clear();
                setErrors(true);
              }
            });
        }
      })
      .catch(error => {
        console.log(error);
      });

    
  }  

  return (
    <Pane id="acceptInvite" height={120} maxWidth={480} marginLeft="auto" marginRight="auto" marginTop={120}>
      <Heading textAlign="center" marginBottom={16}>You've been invited to join <br />{group}<br />on WorkingLit!</Heading>
      <Pane display="flex" flexDirection="column">
        <Checkbox
          label="I agree to the terms and conditions and privacy policy."
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
        />
        <Button margin={8} disabled={!checked} appearance="primary" onClick={e => setEmailmode(!emailmode)}>
          Signup with Email
        </Button>
        <SignupForm emailmode={emailmode} mode="invite" pubid={pubid} handleSignupFromInvite={handleSignupFromInvite} />
        {/* <SocialLogin logintype="Signup" active={checked} /> */}
      </Pane>
    </Pane>
  )
}

export default AcceptInvite;