import { gql } from 'apollo-boost';

const DELETE_ROYALTY_MUTATION = gql`
  mutation DeleteRoyaltyMutation(
    $id: Int!,
  ) {
    deleteRoyalty(
      id: $id,
    ) {
      message
    }
  }
`;

export default DELETE_ROYALTY_MUTATION;